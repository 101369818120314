import React, { Component } from "react";
import Slider from "react-slick";
import { doodle } from "../../constant";

function Testimonial(props) {
  const { item, index } = props;
  const settings = {
    arrow: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="testimonial" key={index}>
        <div className="row slide-padding">
          <div className="col-md-6">
            <div className="slide-text">
              <h2>Testimonials</h2>
              <div className="man">
                <p>{item?.desc}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="right-border">
              <div className="inner-container">
                <div className="user-profile">
                  <img src={item?.img} className="image-fluid image-width" />
                </div>
                <div>
                  <h3 className="user-name">{item?.name}</h3>
                  <p className="user-subText"> {item?.subTitle}</p>
                </div>
                <div className="doodle">
                  <img src={doodle} className="image-fluid image-width" />
                </div>
              </div>
              <p className="desc">{item?.test}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
