import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
const stripePromise = loadStripe(
  "pk_test_51Htt4kBWz16oBSwkY9pAtAjnUTMRJgTO2IT9kAFvhOWAr7azMnMD5ArGoYDVUVIw0j592abv5KvRm2HCAuw4p5Zt00415ahcE4"
);

const CheckoutForm = (props) => {
  const { PaymentHandler,  setCardToken, cardToken } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    setCardToken(payload?.token?.id);
    console.log("check", payload);
    if (payload?.token?.id) {
      PaymentHandler(event);
      return;
    } else {
      toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />
      
        <ul className="list-inline">
          <li>
            <button
              type="submit"
              className="default-btn stripePay-Btn btn "
              disabled={!stripe || !elements || paybutton}
            >
            Payment
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
  const { PaymentHandler, setCardToken, cardToken} = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm  
         PaymentHandler={PaymentHandler}
         setCardToken={setCardToken}
         cardToken={cardToken}
      />
    </Elements>
  );
};

export default StripeForm;
