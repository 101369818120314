import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import AllReviews from "../../components/Review/AllReviews";
import ReviewBox from "../../components/Review/ReviewBox";
import { blogdetail, post1, post2, post3 } from "../../constant";

function BlogDetails() {
  const location = useLocation();
  const Token = useSelector((state) => state.AuthReducer.token);
  const ParamData = location?.state?.data;
  console.log(ParamData);
  const [checked, setChecked] = useState("");

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const recentPost = [
    {
      id: 1,
      title: "Lorem ipsum dolor sit amet",
      img: post1,
    },
    {
      id: 2,
      title: "Lorem ipsum dolor sit amet",
      img: post2,
    },
    {
      id: 3,
      title: "Lorem ipsum dolor sit amet",
      img: post3,
    },
  ];
  return (
    <>
      <Header 
         setChecked={setChecked}
      />
      <UrlHeader />
      <div className="blog-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="image-box productDetail-image">
                <img
                  src={ParamData?.image_url}
                  alt=""
                  className="image-fluid image-width"
                />
              </div>
              <div>
                <p className="heading mt-4">
                 {ParamData?.title}
                </p>
                <p className="desc"
                    dangerouslySetInnerHTML={{ __html: ParamData?.content }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="recentPost">
                <div className="header">
                  <p className="title">Recent Post</p>
                </div>
                {/* {recentPost?.map((item, index) => {
                  return (
                    <div className="postcard" key={index}>
                      <div className="image-box">
                        <img
                          src={item?.img}
                          alt=""
                          className="image-fluid image-width"
                        />
                      </div>
                      <div>
                        <p className="desc">{item?.title}</p>
                      </div>
                    </div>
                  );
                })} */}
              </div>
            </div>
          </div>
          <div>
            <AllReviews />

            <hr />

            <ReviewBox />
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default BlogDetails;
