import React, { useState, useEffect } from "react";
import "../../assets/css/Wishlist.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import Footer from "../../components/Footer/Footer";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { about, wish1 } from "../../constant";
import SideBar from "../../components/SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { ClearWishList, DeleteWishList, GetWishList } from "../../network/Network";
import { RemoveWishListData, WishListData } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import { AddToCart } from "../../redux/actions/CartActions";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

const WishList = (props) => {
  const dispatch = useDispatch()
  const Token = useSelector((state) => state.AuthReducer.token);
  const UserData = useSelector((state) => state.AuthReducer.users);
  const wishlistDataRedux = useSelector(
    (state) => state.AuthReducer.wishlistData
  );
  const allStates = useSelector((state) => state.CartReducer.cartData);
  console.log(wishlistDataRedux)
const [ spinLoad, setSpinLoad ] = useState(false)
const [ bookMarkData ,setBookMarkData] = useState([])
const [pageCount, setPageCount] = useState();
const [currentPage, setCurrentPage] = useState(1);
const [ clearLoading, setClearLoading] = useState(false);
const [checked, setChecked] = useState("");

const handleSelectCategory = (id) => {
  setChecked(id);
};

const handlePageClick = async (data) => {
  console.log(data.selected);
  setCurrentPage(data?.selected + 1);
};

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    // Get WishList
    useEffect(() => {
      setSpinLoad(true);
      GetWishList(currentPage, Token)
        .then((res) => {
          console.log(res);
          setSpinLoad(false);
          setBookMarkData(res?.data?.data?.wishlist?.data);
          dispatch(WishListData(res?.data?.data?.wishlist?.data));
          const total = res?.data?.data?.wishlist?.total;
          const limit = res?.data?.data?.wishlist?.per_page;
          setPageCount(Math.ceil(total / limit));
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    }, [currentPage]);

    const DeleteWishListHandler = (id, index) => {
      console.log(id);
      setSpinLoad(true);
      DeleteWishList(id, Token)
        .then((res) => {
          console.log(res?.data?.data);
          setSpinLoad(false);
          dispatch(RemoveWishListData(id));
          // bookMarkData?.splice(index, 1);
          // setBookMarkData(res?.data?.response?.data);
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    };

    const ClaerBookmarkHandler = (e) => {
      e.preventDefault();
      setClearLoading(true);
      ClearWishList(UserData?.id, Token)
        .then((res) => {
          setClearLoading(false);
          console.log(res);
          dispatch(WishListData(res?.data?.data));
          setBookMarkData(res?.data?.data);
          toast.success("successfully removed!");
        })
        .catch((err) => {
          setClearLoading(false);
          console.log(err);
        });
    };

    const AddToCartHandler = (item) => {
      let checkItemAlreadyExist = allStates.filter(
        (val) => val?.id === item?.product?.id
      );
      if (checkItemAlreadyExist.length > 0) {
        toast.info("Item Already Exist in Cart");
        return;
      } else {
        let colorData = JSON.parse(item?.product?.colors);
        let data = {
          id: item?.product?.id,
          price: item?.product?.unit_price,
          quantity: 1,
          color: colorData[0],
          productitem: item?.product,
        };
        dispatch(AddToCart(data));
        DeleteWishListHandler(item?.product?.id);
      }
    };

  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"My Wishlist"} />
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Wishlist</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <section className="bookmark">
                <div className="container">
                  {/* <div className="heading">
                    <h3 className="title text-center title font-40 fw-600">
                      WishList
                    </h3>
                    <p className="desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore.
                    </p>
                  </div> */}

                  <div className="tableDv">
                  {spinLoad ? (
                      <>
                      <div className="loader-container">
                        <SpinnerCircular size="80px" color="#d60012" />
                      </div>
                      </>
                      ) : (
                        <>
                    <table>
                      <thead>
                        <tr className="topbar">
                          <th className="productDetail">PRODUCT NAME</th>
                          <th className="Proprice">PRICE</th>
                          <th className="Proprice">AVALABLITY</th>
                          <th className="addcart">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wishlistDataRedux?.map((item,index)=>{
                          return(
                            <tr className="products bookmark" key={item?.id}>
                            <td className="productDetail">
                              <div className="product-detail">
                                <div className="deleteBtn"
                                   onClick={() => DeleteWishListHandler(item?.product?.id)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="imgBox">
                                  <figure>
                                    <img
                                     src={`${item?.product?.thumbnail_url}`}
                                      className="image-fluid image-width"
                                      alt=""
                                    />
                                  </figure>
                                </div>
                                <div>
                                  <p className="ProductName">
                                   {item?.product?.name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="ProductName">${item?.product?.unit_price}</p>
                            </td>
                            <td>
                              <p className="ProductName">IN STOCK</p>
                            </td>
                            <td className="addcart">
                              <div className="ActionBtn">
                                <div className="cartBtn">
                                  <button
                                    type="button"
                                    className="btn addToCart-btn"
                                    onClick={() => AddToCartHandler(item)}
                                  >
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                          )
                        })}
                       
                      </tbody>
                    </table>
                    <div className="pagination-container mt-5">
                        <ReactPaginate
                          // renderOnZeroPageCount={null}
                          previousLabel="<<"
                          nextLabel=">>"
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-center"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                        </>
                      )
                    }
                   
                  </div>
                  <div className="row align-items-baseline">
                    <div className="col-md-8">
                      <div className="button-group">
                        <Link to="/" className="btn">
                          Continue Shopping
                        </Link>
                        <a 
                          className="btn grey"
                          onClick={(e) => ClaerBookmarkHandler(e)}
                        >
                          {clearLoading ? "Loading..." : "Clear Bookmarks"}
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <NewsLetter />
      <Footer />
    </>
  );
};

export default WishList;
