import { faCartShopping, faEye, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddWishList } from "../../network/Network";
import { AddToCart } from "../../redux/actions/CartActions";


function PopularCard(props) {
  const { item, index } = props;
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const allStates = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);

  const AddToCartHandler = (item) => {
    let checkItemAlreadyExist = allStates.filter((val) => val?.id == item?.id);
    if (checkItemAlreadyExist.length > 0) {
      toast.info("Item Already Exist in Cart");
      return;
    } else {
      let colorData = JSON.parse(item?.colors);
      let data = {
        id: item?.id,
        price: item?.unit_price,
        quantity: 1,
        color: colorData[0],
        productitem: item,
      };
      dispatch(AddToCart(data));
      toast.success("Product Add to your Cart");
    }
  };

  const AddWishListHandler = (e) => {
    e.preventDefault();
    let data = {
      product_id: item?.id,
    };
    console.log(data);
    AddWishList(data, Token)
      .then((res) => {
        console.log(res);
        toast.success("Product Add to your Wishlist");
        // dispatch(WishListData(res?.data?.data?.wishlist))
      })
      .catch((err) => {
        console.log(err?.response);
        toast.error(err?.response?.data?.message);
      });
  };
 
  return (
    <>
      <div className="col-md-4" key={index}>
        <div className="pop-card"
      
        >
          <div className="pop-card-img">
            <img src={`${item?.thumbnail_url}`} className="image-fluid image-width" alt="" />
          </div>
          <div className="pop-cardHover">
            <div className="inner-hoverDiv">
              <FontAwesomeIcon
                icon={faHeart}
                className="social-color icon-hover"
                onClick={(e)=>AddWishListHandler(e)}
              />
               <FontAwesomeIcon
                icon={faEye} 
                className="social-color icon-hover"
                onClick={()=> Navigate(`/productdetails/${item?.id}`, { state: { data: item } })}
              />
              <FontAwesomeIcon
                icon={faCartShopping}
                className="social-color icon-hover"
                onClick={()=>AddToCartHandler(item)}
              />
            </div>
          </div>
          <p className="desc" dangerouslySetInnerHTML={{ __html: item?.details }} />
          {/* <p className="desc">{item?.text}</p> */}
        </div>
      </div>
    </>
  );
}

export default PopularCard;
