import React, { useEffect, useState } from "react";
import Avail from "../../components/Avail/Avail";
import Banner from "../../components/Banner/Banner";
import PopularCard from "../../components/Card/PopularCard";
import ProductCard from "../../components/Card/ProductCard";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import TopSlider from "../../components/Slider/TopSlider";
import Testimonial from "../../components/Testimonial/Testimonial";
import {
  avail,
  popular1,
  popular2,
  popular3,
  popular4,
  popular5,
  popular6,
  arrival1,
  arrival2,
  arrival3,
  subSlider1,
  subSlider2,
  subSlider3,
  p1,
  blog1,
  blog2,
  blog3,
  pre,
  next,
  next2,
  prev2,
} from "../../constant";
import Slider from "react-slick";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import FollowUs from "../../components/FollowUs/FollowUs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";
import { GetAllProducts } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { useSelector } from "react-redux";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <img src={next} alt=" " className="image-fluid image-nextPrev " />
      </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
       <img src={pre} alt=" " className="image-fluid image-nextPrev " />
      </div>
  );
}

function SampleNextArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
       <img src={next2} alt=" " className="image-fluid image-nextPrev " />
      </div>
  );
}

function SamplePrevArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
       <img src={prev2} alt=" " className="image-fluid image-nextPrev " />
      </div>
  );
}

function Home() {
  const Navigate = useNavigate();
  const BlogsReduxData = useSelector((state) => state.AuthReducer.blogs);
  const HotDeals = useSelector((state) => state.AuthReducer.hotDeals);

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />
  };
  const subSlider = {
    arrow: true,
    dots: true,
    infinite: true,
    // speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const popularData = [
    {
      id: 1,
      img: popular1,
      text: "Lorem Ipsum is simply dummy text",
    },
    {
      id: 2,
      img: popular2,
      text: "Lorem Ipsum is simply dummy text",
    },
    {
      id: 3,
      img: popular3,
      text: "Lorem Ipsum is simply dummy text",
    },
    {
      id: 4,
      img: popular4,
      text: "Lorem Ipsum is simply dummy text",
    },
    {
      id: 5,
      img: popular5,
      text: "Lorem Ipsum is simply dummy text",
    },
    {
      id: 6,
      img: popular6,
      text: "Lorem Ipsum is simply dummy text",
    },
  ];
  const newArrivalData = [
    {
      id: 1,
      img: arrival1,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 2,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 3,
      img: arrival3,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
  ];

  const TestimonialData = [
    {
      id: 1,
      img: p1,
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "Silvia Natalia",
      subTitle: "Owner Tanahcon",
      test: "“It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.”",
    },
    {
      id: 2,
      img: p1,
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "Silvia Natalia",
      subTitle: "Owner Tanahcon",
      test: "“It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.”",
    },
    {
      id: 3,
      img: p1,
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "Silvia Natalia",
      subTitle: "Owner Tanahcon",
      test: "“It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.”",
    },
  ];

  const SubSliderData = [
    {
      id: 1,
      img: subSlider1,
    },
    {
      id: 2,
      img: subSlider2,
    },
    {
      id: 3,
      img: subSlider3,
    },
    {
      id: 4,
      img: subSlider1,
    },
    {
      id: 5,
      img: subSlider2,
    },
    {
      id: 6,
      img: subSlider3,
    },
  ];

  const AvailData = [
    {
      id: 1,
      img: avail,
      title: "Avail 30% Off",
      desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.",
    },
  ];

  const blogData = [
    {
      id: 1,
      img: blog1,
      desc: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    },
    {
      id: 2,
      img: blog2,
      desc: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    },
    {
      id: 3,
      img: blog3,
      desc: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    },
  ];
  const navigate = useNavigate();

  const navigateToProducts = () => {
    // 👇️ navigate to /contacts
    navigate('/all-product-page');
  };
  useEffect(() => {
    Aos.init();
  },[])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const [ spinLoad, setSpinLoad ] = useState(false)
  const [ productsData, setProductsData ] = useState([])
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState("");
  
  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllProducts(currentPage)
      .then((res) => {
        console.log(res);
        setProductsData(res?.data?.data?.products?.data);
        // setProductImages(res?.data?.data?.products?.data);
        const total = res?.data?.data?.products?.total;
        const limit = res?.data?.data?.products?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header 
         setChecked={setChecked}
      />
      <Banner />
      <div className="top-subSlider">
        <div className="container">
          <div className="sub-header-text">
          <p className="ptext">Product Categories</p>
          <p><Link to="/products/:Id" className="plink">View All</Link></p>
          </div>
          <Slider {...subSlider}>
            {SubSliderData?.map((item, index) => {
              return <TopSlider item={item} index={index} />;
            })}
          </Slider>
        </div>
      </div>

      <div className=" container ">
        <div className="newArival-container">
          <h2 className="title">
            New <span>Arrivals</span>
          </h2>
          <div>
            <p className="para mt-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="product-card">
            <div className="row">
              { spinLoad ? (
                    <>
                    <div className="loader-container">
                        <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                    </>
                  ) : (
                    <>
                      {productsData?.map((item, index) => {
                        return (
                          <>
                          {index < 3 ? (
                            <>
                              <div className="col-md-4">
                              <ProductCard item={item} index={index} />
                            </div>
                            </>
                          ) : (null)}
                            
                          </>
                        );
                      })}
                    </>
                  )
                }
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="/all-product-page" className="seeAll">See all</Link>
          </div>
        </div>
      </div>
      <PercentOffAdds />
      <div className="popular-section">
        <div className="container">
          <div className="popular-header">
            <h2 className="title">
              Popular <span>Items</span>
            </h2>
            <p className="para">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div>
            <div className="row">
              {HotDeals?.map((item, index) => {
                return(
                  <>
                  {index <6 ? (
                    <>
                    <PopularCard 
                      item={item} 
                      index={index} 
                    />
                    </>
                  ) : ( null)}
                  
                  </>
                ) 
              })}
            </div>
            <div className="button-container">
              <button type="button" className="btn btn-primary"
                onClick={()=>Navigate("/all-product-page")}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial-bg">
        <div className="container">
          <Slider {...settings}>
            {TestimonialData?.map((item, index) => {
              return <Testimonial item={item} index={index} />;
            })}
          </Slider>
        </div>
      </div>

      <Avail page={"home"} AvailData={AvailData} />
      <div className="blog-page home-blog-page">
        <div className="container">
          <h2 className="title">
            {/* News <span>&</span> Events */}
            Blog
          </h2>
          <div className="row">
            {BlogsReduxData?.map((item, index) => {
              return (
                <>
                {index < 3 ? (
                  <>
                  <div className="col-md-4 mb-4" key={index}>
                  <div className="card h-100"
                   data-aos="zoom-in-up"
                   data-aos-duration="3000"
                  >
                    <div className="image-box">
                      <img
                        src={blog1}
                        alt=""
                        className="image-fluid image-width"
                      />
                    </div>
                    <div className="header">
                      <p className="mr-2">NeWS</p>
                      <p>| {item?.created_at}</p>
                    </div>
                    <p className="desc">{item?.title}</p>
                    {/* <Link to={`/blogdetail/${item?.id}`} className="readMore"> */}
                    <Link 
                      to={`/blogdetail/${item?.id}`} state={{ data: item }} 
                      className="readMore"
                    >
                      Read more{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="arrow-icon"
                      />
                    </Link>
                  </div>
                </div>
                  </>
                ) : (null)}
               </>
              );
            })}
          </div>
          <div className="button-container">
              <button type="button" 
                className="btn btn-primary"
                onClick={()=>Navigate("/blog")}
              >
                View All
              </button>
            </div>
        </div>
      </div>
      <FollowUs />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default Home;
