import Aos from "aos";
import React, { useEffect } from "react";

function OurTeamCard(props) {
  const { item, index } = props;
  useEffect(() => {
    Aos.init();
  },[])

  return (
    <>
      <div className="col-md-3" key={index}
        data-aos="flip-up"
        data-aos-duration="3000"
      >
        <div className="image-container">
          <img src={item?.img} alt="" className="image-fluid image-width" />
        </div>
        <div className="team-detail">
          <div className="inner-container">
            <p className="title">{item?.name}</p>
            <p className="sub-title">{item?.desc}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurTeamCard;
