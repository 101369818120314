import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import { pink } from "@mui/material/colors";
import "../../assets/css/Checkout.css";


function AddressCard(props) {
    const { item, index, handleChange, selectedValue, setSelectedValue} = props;
  return (
    <>
           <div className="radio_add_box p-3 pt-4 d-flex mb-3" key={index}>
                      <div className="brdr-right-grey_radio pe-2">
                        <Radio
                         checked={selectedValue === 'a'}
                         onChange={handleChange}
                         value="a"
                         name="set_address_radio"
                        //  inputProps={{ 'aria-label': 'A' }}
                          // name="set_address_radio"
                          // {...controlProps("first")}
                          sx={{
                            color: pink[800],
                            "&.Mui-checked": {
                              color: pink[600],
                            },
                          }}
                        />
                      </div>
                      <div className="row width_full ps-3">
                        <div className="col-lg-6">
                          <p className="text-dark font-roboto-medium f-16">
                            Country :{" "}
                            <span className="gry-clr font-roboto-regular">
                              {item?.country}
                            </span>
                          </p>
                        </div>

                        <div className="col-lg-6">
                          <p className="text-dark font-roboto-medium f-16">
                            {" "}
                            State :{" "}
                            <span className="gry-clr font-roboto-regular">
                             {item?.state}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <p className="text-dark font-roboto-medium f-16">
                            {" "}
                            Address :{" "}
                            <span className="gry-clr font-roboto-regular">
                             {item?.address}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <p className="text-dark font-roboto-medium f-16">
                            {" "}
                            Phone :{" "}
                            <span className="gry-clr font-roboto-regular">
                             {item?.phone}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="col-lg-6 text-right">
                          <button className="default_btn f-13 font-roboto">
                            Default
                          </button>
                        </div>
                      </div>
                    </div>
    </>
  )
}

export default AddressCard