import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Signup.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { signUp } from "../../redux/actions/AuthActions";

const Signup = () => {
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [address, setAddress] = useState("");
	const [userState, setUserState] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [country, setCountry] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [btnLoading, setBtnLoading] = useState("");
	const [checked, setChecked] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
});

const handleClickShowPassword = () => {
  setValues({ ...values, showPassword: !values.showPassword });
};

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

const handlePasswordChange = (prop) => (event) => {
  setValues({ ...values, [prop]: event.target.value });
};

	const handleSelectCategory = (id) => {
		setChecked(id);
	};

	const SignUpHandler = async (e) => {
		e.preventDefault();
		setBtnLoading(true);
		if (
			!firstName ||
			!lastName ||
			!email ||
			!phone ||
			!address ||
			!userState ||
			!zipCode ||
			!country ||
			!password
		) {
			toast.error("Please Enter All Fields");
			setBtnLoading(false);
			return;
		}
		if (password.length > 8) {
			toast.error("please Enter 8 Characters only");
			setPassword(false);
			setBtnLoading(false);
			return;
		}

		if (phone.length > 11) {
			toast.error("please Enter only 11 digits");
			setPhone(false);
			setBtnLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setBtnLoading(false);
			return;
		} else {
			let data = {
				f_name: firstName,
				l_name: lastName,
				email: email,
				phone: phone,
				password: password,
				street_address: address,
				country: country,
				city: userState,
				zip: zipCode,
        confirm_password:confirmPassword,
			};
			console.log(data);
			setBtnLoading(false);
			let send = await dispatch(signUp(data));
			Navigate("/Signin");
		}
	};

	return (
		<>
			<Header setChecked={setChecked} />
			<UrlHeader page={"Sign Up"} />
			<div className="pos-rel signup">
				<div className="container dark_black_div">
					<div className="row">
						<div className="col-lg-12 text-center">
							<h3 className="mt-4rem font-roboto f-31">
								Register Your Account
							</h3>
							<p className="f-16">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore.
							</p>

							<div className="form-signup px-3">
								<p className="font-mon-semibold mt-5">First Name</p>
								<input
									type="text"
									placeholder="Enter Your First Name*"
									className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
								<p className="font-mon-semibold mt-3">Last Name</p>
								<input
									type="text"
									placeholder="Enter Your Last Name*"
									className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
								<p className="font-mon-semibold mt-3">Email</p>
								<input
									type="email"
									placeholder="Enter Your Email*"
									className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<p className="font-mon-semibold mt-3">Phone</p>
								<input
									type="number"
									placeholder="Enter Your Phone*"
									className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
								<p className="font-mon-semibold mt-3">Address</p>
								<input
									type="text"
									placeholder="Enter Your Address*"
									className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
								<div className="row">
									<div className="col-lg-4">
										<p className="font-mon-semibold mt-3">State</p>
										<input
											type="text"
											placeholder="Enter State*"
											className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
											value={userState}
											onChange={(e) => setUserState(e.target.value)}
										/>
									</div>
									<div className="col-lg-4">
										<p className="font-mon-semibold mt-3">Zip Code</p>
										<input
											type="text"
											placeholder="Enter Zip Code*"
											className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
											value={zipCode}
											onChange={(e) => setZipCode(e.target.value)}
										/>
									</div>
									<div className="col-lg-4">
										<p className="font-mon-semibold mt-3">Country</p>
										<input
											type="text"
											placeholder="Enter Country*"
											className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
											value={country}
											onChange={(e) => setCountry(e.target.value)}
										/>
									</div>

									<div className="col-lg-12">
										<p className="font-mon-semibold mt-3">Street</p>
										<input
											type="text"
											placeholder="Enter Country*"
											className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										/>
									</div>
									{/* 
                  <div className="col-lg-6">
                    <p className="font-mon-semibold mt-3">City</p>
                    <input
                      type="text"
                      placeholder="Enter Country*"
                      className="form-control f-13 font-mon-meduim signup-fld rounded-0 p-3"
                      value={userState}
                      onChange={(e)=>setUserState(e.target.value)}
                    />
                  </div> */}
								</div>
								<div className="confirm-password-icon">
									<p className="font-mon-semibold mt-3">Password</p>
									<input
										type={values.showPassword ? "text" : "password"}
										placeholder="Enter Your Password*"
										className="form-control signup-fld f-13 font-mon-meduim rounded-0 p-3"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<div className="icon-password">
										<i onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}  class="fa fa-eye" aria-hidden="true"></i>
									</div>
								</div>
								{/* <i class="fa fa-eye" aria-hidden="true"></i> */}
								<div className="confirm-password-icon">
									<p className="font-mon-semibold mt-3">Confirm Password</p>
									<input
										type={values.showPassword ? "text" : "password"}
										placeholder="Enter Your Confirm Password"
										className="form-control signup-fld f-13 font-mon-meduim rounded-0 p-3"
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
									<div className="icon-password">
										<i onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword} class="fa fa-eye" aria-hidden="true"></i>
									</div>
								</div>
								<input type="checkbox" className="mt-4" />{" "}
								<span className="font-pop-reg f-15 mt-4 ms-2">
									By clicking "create account", I consent to the privacy policy.
								</span>
								<div className="d-flex">
									<div className="border-red mt-4">
										<button
											className="view_more btn f-17 font-roboto-medium"
											onClick={(e) => SignUpHandler(e)}
											disabled={btnLoading}
										>
											{btnLoading ? "Loading.." : "Create An Account"}
										</button>
									</div>
								</div>
								{/*<div className="by_creating_acc">*/}
								{/*  <p className="font-pop f-15 mt-5">*/}
								{/*    By creating an account, you agree to our:*/}
								{/*  </p>*/}
								{/*</div>*/}
								{/*<div className="by_creating_acc">*/}
								{/*  <p className="font-pop f-21  fw-bold">*/}
								{/*    Terms of Condition I Privicy Policy*/}
								{/*  </p>*/}
								{/*</div>*/}
								<div className="by_creating_acc">
									<Link
										to="/signin"
										className="font-pop-reg f-15 clr-red mt-5 mb-5"
									>
										Already have an account?{" "}
										<span className="text-white font-robot-medium f-15 ms-3">
											Sign In
										</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Signup;
