import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Update_logo, footerB1, footerB2, footerlogo } from "../../constant";
import { CompanyInfo, GetNews } from "../../network/Network";
import { Blogs, CompanyInfoStore } from "../../redux/actions/AuthActions";

const Footer = () => {
  const dispatch = useDispatch()
  const [ newsData, setNewsData ] = useState([])

    // Get CompanyInfo
    useEffect(() => {
      CompanyInfo()
        .then((res) => {
          console.log(res);
          dispatch(CompanyInfoStore(res?.data?.data?.contact));
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

        // Get News
        useEffect(() => {
          GetNews()
            .then((res) => {
              console.log(res);
              setNewsData(res?.data?.data?.blogs);
              dispatch(Blogs(res?.data?.data?.blogs))
            })
            .catch((err) => {
              console.log(err);
            });
        }, []);

        console.log(newsData)
  return (
    <>
      <footer className="footer cover-banner">
        <div className="container">
          <div className="inner-container">
            <div className="row pb-3">
              <div className="col-md-4 widgets2 border-right">
                <div className="footer-logo">
                  <img src={Update_logo} className="image-fluid" alt="" />
                </div>
                <h4 className="pb-3 first-col">
                  Many businesses, large and small, have a huge source of great
                  ideas that can help them improve, innovate, and grow, and yet
                  so many of these companies.
                </h4>
              </div>
              <div className="col-md-4 widgets2 border-right">
                <h4 className="pb-3 text-white mainText">Quick links</h4>
                <div>
                  <ul>
                    <li>
                      <Link to="/shippinginfo"> Shipping Information </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy"> Privacy Policy </Link>
                    </li>
                    <li>
                      <Link to="/refund-policy"> Return Policy </Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy"> Cookies Policy</Link>
                    </li>
                    <li>
                      <Link to="/term-condition"> Terms and conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 widgets2">
                <h4 className="pb-3 text-white mainText" style={{textAlign: 'center'}}>Recent Blog</h4>
                <div className="blog">
                  {newsData?.map((item,index)=>{
                    return(
                      <>
                      {index < 2 ? (
                          <div className="blog-container mb-4" key={index}>
                          <div className="footer-profile">
                            <img src={footerB1} className="image-fluid image-width" />
                          </div>
                          <div>
                            <h4 className="title">{item?.title}</h4>
                            <p className="title m-0" 
                               dangerouslySetInnerHTML={{ __html: item?.content }}
                            />
                            <p className="date">{item?.updated_at}</p>
                          </div>
                        </div>
                      ) : (
                        null
                      )}
                      </>
                    
                    )
                  })}
                  {/* <div className="blog-container mb-4">
                    <div className="footer-profile">
                      <img src={footerB1} className="image-fluid image-width" />
                    </div>
                    <div>
                      <h4 className="title">Lorem Ipsum</h4>
                      <p className="title">Dolor Sit Amet consectetur</p>
                      <p className="date">Nov 25, 2021</p>
                    </div>
                  </div>
                  <div className="blog-container mb-4">
                    <div className="footer-profile">
                      <img src={footerB2} className="image-fluid image-width" />
                    </div>
                    <div>
                      <h4 className="title">Lorem Ipsum</h4>
                      <p className="title">Dolor Sit Amet consectetur</p>
                      <p className="date">Nov 25, 2021</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="container-fluid footer-copyright ">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
              <p className="copyright">
                Copyright © 2021 - Your Beautiful. All Right Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
