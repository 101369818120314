import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Cart.css";
import CartList from "../../components/Cart/CartList";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { cart1 } from "../../constant";
import { ApplyCoupons } from "../../network/Network";
import { ClearCart, DecrementProductQuantity, deleteCartItem, IncrementProductQuantity } from "../../redux/actions/CartActions";

const ProductCart = () => {
  const dispatch = useDispatch();
  const CartData = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const [cartData, setCartData] = useState([CartData]);
  const [subTotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [coupons, setCoupons] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [checked, setChecked] = useState("");
  

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < CartData.length; i++) {
      console.log("cart", CartData[i]?.price);
      total = total + CartData[i]?.price * CartData[i]?.quantity;
    }
    setSubTotal(total);
  };
  console.log(CartData);

  useEffect(() => {
    TotalAmount();
  }, [CartData]);

  const RemoveCartItem = (id) => {
    const items = cartData;
    let currentItem = id;
    if (items.length > 0) {
      setCartData(
        items.filter((item, index) => item?.productitem?.id !== currentItem)
      );
      dispatch(deleteCartItem(currentItem));
      //   setPostList(items.filter((item, index) => index !== currentItem));
    }
  };

  const Increment = (id) => {
    dispatch(IncrementProductQuantity(id));
  };
  const Decrement = (id) => {
    dispatch(DecrementProductQuantity(id));
  };

  const ApplyCouponsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(coupons);
    ApplyCoupons(coupons)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setCouponData(res?.data?.data?.coupon);
        toast.success("Successfully Apply");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response?.data?.message);
        toast.error(err?.response?.data?.message);
      });
  };
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"Cart"}/>
      <section className="productCart">
        <div className="container">
          <div className="heading">
            <h3 className="text-center title font-40 fw-600 title">
              Product Cart
            </h3>
            <p className="desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore.
            </p>
          </div>
          <div className="tableDv">
            <table>
              <thead>
                <tr className="topbar">
                  <th className="productDetail">PRODUCT NAME</th>
                  <th className="Proprice">UNIT PRICE</th>
                  <th className="qty">QUANTITY</th>
                  <th className="addcart">SUB TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {CartData?.map((item, index) => {
                  return <CartList 
                            item={item} 
                            index={index} 
                            RemoveCartItem={RemoveCartItem}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            Increment={Increment}
                            Decrement={Decrement}
                          />;
                })}
              </tbody>
            </table>

            <div className="row pt-5">
              <div className="col-md-6">
                <div className="discountCode">
                  <div className="head">
                    <p>Discount codes</p>
                  </div>
                  <div className="applyCoupon">
                    <input 
                      type="text" 
                      placeholder="Enter your coupon code if you have one."
                      value={coupons}
                      onChange={(e) => setCoupons(e.target.value)}
                    />
                    <button
                       onClick={(e) => ApplyCouponsHandler(e)}
                       disabled={loading}
                    >
                      {loading ? "Loading.." : "APPLY"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <div className="productSummary">
                  <div className="subTotal">
                    <h6 className="rightt">Subtotal</h6>
                    <h6>Estimated Shipping Cost</h6>
                    <h6>Coupon Discount</h6>
                  </div>
                  <div className="shipping">
                    <h6 className="price">${subTotal}</h6>
                    <h6>Free Shipping</h6>
                    <h6 className="price">${couponData?.discount}</h6>
                  </div>
                </div>
                <div className="Total">
                  <div className="property">
                    <p>TOTAL COST</p>
                  </div>
                  <div className="value">
                    <p>$ 
                      {couponData?.discount
                        ? subTotal - couponData?.discount
                        : subTotal}
                        </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-baseline">
            <div className="col-md-6">
              <div className="button-group">
                <Link to="/" className="btn">
                  Continue Shopping
                </Link>
                <a  
                  className="btn grey"
                  onClick={() => dispatch(ClearCart())}
                >
                  Clear
                </a>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="proceed">
                <Link 
                 to={`/Checkout`}
                 state={{
                   data: {
                     CartData: CartData,
                     total: subTotal,
                     couponData: couponData,
                   },
                 }}
                   className="btn"
                  >
                  Proceed To Checkout
                </Link>

                <h6 style={{ color: "#989898" }}>
                  You’ll still have a chance to review your order
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default ProductCart;
