import {
	faCartShopping,
	faEye,
	faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { AddWishList } from "../../network/Network";
import { WishListData } from "../../redux/actions/AuthActions";
import { AddToCart } from "../../redux/actions/CartActions";
import { useState } from "react";

function ProductCard(props) {
	const { item, index } = props;
	const [rating, setRating] = useState(0);
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const allStates = useSelector((state) => state.CartReducer.cartData);
	const Token = useSelector((state) => state.AuthReducer.token);
	console.log(item);

	const handleRating = (rate) => {
		console.log("rate", rate);
		if (rate === 20) {
			setRating(1);
		} else if (rate === 40) {
			setRating(2);
		} else if (rate === 60) {
			setRating(3);
		} else if (rate === 80) {
			setRating(4);
		} else {
			setRating(5);
		}
	};

	const AddToCartHandler = (item) => {
		let checkItemAlreadyExist = allStates.filter((val) => val?.id == item?.id);
		if (checkItemAlreadyExist.length > 0) {
			toast.info("Item Already Exist in Cart");
			return;
		} else {
			let colorData = JSON.parse(item?.colors);
			let data = {
				id: item?.id,
				price: item?.unit_price,
				quantity: 1,
				color: colorData[0],
				productitem: item,
			};
			dispatch(AddToCart(data));
			toast.success("Product Add to your Cart");
		}
	};

	const AddWishListHandler = (e) => {
		e.preventDefault();
		let data = {
			product_id: item?.id,
		};
		console.log(data);
		AddWishList(data, Token)
			.then((res) => {
				console.log(res);
				toast.success("Product Add to your Wishlist");
				// dispatch(WishListData(res?.data?.data?.wishlist))
			})
			.catch((err) => {
				console.log(err?.response);
				toast.error(err?.response?.data?.message);
			});
	};
	return (
		<>
			<div className="card" key={index}>
				<a
					// to={`/productdetails/${item?.id}`}
					// state={{ data: item }}
					className="card-image"
				>
					<img
						src={`${item?.thumbnail_url}`}
						alt=""
						className="image-fluid image-width"
					/>
					<div className="pop-cardHover">
						<div className="inner-hoverDiv">
							<FontAwesomeIcon
								icon={faHeart}
								className="social-color icon-hover"
								onClick={(e) => AddWishListHandler(e)}
							/>
							<FontAwesomeIcon
								icon={faEye}
								className="social-color icon-hover"
								onClick={() =>
									Navigate(`/productdetails/${item?.id}`, {
										state: { data: item },
									})
								}
							/>
							<FontAwesomeIcon
								icon={faCartShopping}
								className="social-color icon-hover"
								onClick={() => AddToCartHandler(item)}
							/>
						</div>
					</div>
				</a>
				<div className="card-inner">
					<div className="content-top">
						{console.log(item.id,"lsaass")}
						<Link
							to={`/productdetails/${item?.id}`}
							state={{ data: item }}
							// state={{ data: item }}
							className="title"
						>
							{item?.name}
						</Link>
						<h4 className="price">{item?.unit_price}</h4>
					</div>
					<div>
						<p
							className="desc"
							dangerouslySetInnerHTML={{ __html: item?.details }}
						/>
					</div>
					<div>
						<Rating onClick={handleRating} ratingValue={rating} />
					</div>
				</div>
			</div>
		</>
	);
}

export default ProductCard;
