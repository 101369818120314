import React, { useEffect, useState } from "react";
import "../../assets/css/Faq.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/Footer/Footer";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { GetFaqs, PostQuestions } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { toast } from "react-toastify";

const Faq = () => {
  const [ fullName, setFullName ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ question, setQuestion ] = useState("")

  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [checked, setChecked] = useState("");

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  // Get fAQS
  useEffect(() => {
    setSpinLoad(true);
    GetFaqs()
      .then((res) => {
        console.log(res);
        setFaqData(res?.data?.data?.faqs);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const SendMessage = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!fullName || !email || !question) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (question.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    let data = {
      full_name: fullName,
      email: email,
      message: question,
    };
    console.log(data);
    PostQuestions(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setFullName("");
        setEmail("");
        setQuestion("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Header 
        setChecked={setChecked} 
      />
      <UrlHeader page={"FAQ's"} />
      <div className="pos-rel Faqs">
        <div className="dark_black_div">
          <div className="row">
          {spinLoad ? (
              <>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#d60012" />
                </div>
              </>
            ) : (
              <>
                    <div className="col-lg-12 text-center">
              <div className="accordian_faqs p-3">
                {faqData?.map((item, index) => {
                  return (
                    <Accordion key={index} className="mb-3 border-0">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p className="f-17 font-roboto-medium mb-0 text-start faq-question">
                          {item?.question}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="bordr-top-lightgry pt-3 text-start f-15 font-roboto-regular faq-answer">
                          {item?.answer}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </div>
              </>
            )
          }
        
          </div>
        </div>
        <div className="red_div p-5 p-res-faqs">
          <div className="container">
            <div className="row mt-5">
              <div className="offset-lg-1 col-lg-4 text-start">
                <p className="f-50 font-roboto-medium text-white line-height-normal">
                  Can’t find the answer to your question?
                </p>
                <p className="font-sintony f-16 text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
              <div className=" col-lg-6  text-start">
                <input
                  type="text"
                  placeholder="Your full name"
                  className="form-control inp-faq-page font-roboto-regular"
                  value={fullName}
                  onChange={(e)=>setFullName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Your e-mail address"
                  className="form-control inp-faq-page font-roboto-regular mt-2-3"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
                <textarea
                  rows={6}
                  placeholder="Drop your question here"
                  className="form-control inp-faq-page font-roboto-regular mt-2-3"
                  value={question}
                  onChange={(e)=>setQuestion(e.target.value)}
                ></textarea>
                <div className="border-white  mt-4">
                  <button 
                    className="view_more btn font-17 font-roboto-regular"
                    onClick={(e)=>SendMessage(e)}
                    disabled={loading}
                  >
                   {loading ? "Loading..." : "Send Message" }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Faq;
