import React, { useState, useEffect } from "react";
import "../../assets/css/ProductDetails.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import ProductImagesSlider from "../../components/ProductDetails/ProductImagesSlider";
import ProductDescriptionBox from "../../components/ProductDetails/ProductDescriptionBox";
import AllReviews from "../../components/Review/AllReviews";
import ReviewBox from "../../components/Review/ReviewBox";
import SizeModal from "../../components/Modal/SizeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCartArrowDown,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Heart from "react-heart";
import {
	AddWishList,
	GetWishList,
	PostProductRating,
	SelectedProductReviews,
} from "../../network/Network";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
	AddToCart,
	DecrementProductQuantity,
	IncrementProductQuantity,
	ProductAllReviews,
} from "../../redux/actions/CartActions";
import { WishListData } from "../../redux/actions/AuthActions";

function ProductDetails() {
	const location = useLocation();
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const Token = useSelector((state) => state.AuthReducer.token);
	const WishListRedux = useSelector((state) => state.AuthReducer.wishlistData);
	const ReviewsDataRedux = useSelector((state) => state.CartReducer.reviewData);
	const allStates = useSelector((state) => state.CartReducer.cartData);
	const ParamData = location?.state?.data;
	console.log(ParamData);

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [active, setActive] = useState(false);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");
	const [color, setColor] = useState("");
	const [wishLoading, setWishLoading] = useState(false);
	const [checked, setChecked] = useState("");
	const [rating, setRating] = useState(0);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [productReview, setProductReview] = useState([]);
	const [heartDisable, setHeartDisable] = useState(false);
	const [sizeData, setSizeData] = useState("");
	const [quantity, setQuantity] = useState(1);

	const handleSelectCategory = (id) => {
		setChecked(id);
	};

	const handlePageClick = async (data) => {
		console.log(data.selected);
		setCurrentPage(data?.selected + 1);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleRating = (rate) => {
		console.log("rate", rate);
		if (rate === 20) {
			setRating(1);
		} else if (rate === 40) {
			setRating(2);
		} else if (rate === 60) {
			setRating(3);
		} else if (rate === 80) {
			setRating(4);
		} else {
			setRating(5);
		}
	};

	const SubmitRating = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!rating || !comment) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		let data = {
			product_id: ParamData?.id,
			comment: comment,
			rating: rating,
		};
		PostProductRating(data, Token)
			.then((res) => {
				setLoading(false);
				console.log("rrrv", res);
				dispatch(ProductAllReviews(res?.data?.data));
				toast.success("Successfully send");
				setRating("");
				setComment("");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err?.response?.data?.message);
				if (err?.response?.data?.message === "Unauthenticated.") {
					toast.error("Please Login");
				}
				setRating("");
				setComment("");
			});
	};
	// console.log(Token,"hello")

	// == Product Reviews ==
	useEffect(() => {
		SelectedProductReviews(ParamData?.id, currentPage)
			.then((res) => {
				console.log(res);
				SelectedProductReviews(res?.data?.data?.data);
				dispatch(ProductAllReviews(res?.data?.data?.data));
				const total = res?.data?.data?.total;
				const limit = res?.data?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
			})
			.catch((err) => {
				console.log(err);
			});
	}, [ProductAllReviews, currentPage]);

	const AddWishListHandler = () => {
		// e.preventDefault();
		setWishLoading(true);
		let data = {
			product_id: ParamData?.id,
		};
		console.log(data);
		AddWishList(data, Token)
			.then((res) => {
				setWishLoading(false);
				console.log(res);
				setActive(!active);
				toast.success("Product Add to your Wishlist");
				// dispatch(WishListData(res?.data?.data?.wishlist))
			})
			.catch((err) => {
				setWishLoading(false);
				console.log(err?.response);
				toast.error(err?.response?.data?.message);
			});
	};

	const AddToCartHandler = (item) => {
		let checkItemAlreadyExist = allStates.filter((val) => val?.id === item?.id);
		if (checkItemAlreadyExist.length > 0) {
			alert("Item Already Exist in Cart!!!");
		} else {
			let colorData = JSON.parse(ParamData?.colors);
			let data = {
				id: item?.id,
				price: item?.unit_price,
				quantity: quantity,
				color: color ? color : colorData,
				size: sizeData,
				productitem: item,
			};
			dispatch(AddToCart(data));
			// Navigate("/Product-cart");
		}
	};

	// Get WishList
	useEffect(() => {
		GetWishList(currentPage, Token)
			.then((res) => {
				console.log(res);
				// setBookMarkData(res?.data?.data?.wishlist?.data);
				dispatch(WishListData(res?.data?.data?.wishlist?.data));
				const total = res?.data?.data?.wishlist?.total;
				const limit = res?.data?.data?.wishlist?.per_page;
				setPageCount(Math.ceil(total / limit));
			})
			.catch((err) => {
				console.log(err);
			});
	}, [currentPage]);

	// useEffect(() => {
	//   // toast("start")
	//   let filterHeartData = WishListRedux?.filter(
	//     (val) =>  ((val?.product?.id === ParamData?.id))
	//     );
	//   if(filterHeartData[0]?.product?.id === ParamData?.id){
	//     setActive(true)
	//   }
	// }, [active])

	const Increment = (id) => {
		setQuantity(quantity + 1);
	};
	const Decrement = (id) => {
		setQuantity(quantity - 1);
	};

	return (
		<>
			<Header setChecked={setChecked} />
			<UrlHeader page={"Product Details"} />
			<div className="product-details">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<ProductImagesSlider ParamData={ParamData.images} />
						</div>
						<div className="col-md-6">
							<div>
								<h3 className="productName">{ParamData?.name}</h3>
								<div className="price-heart">
									<div className="d-flex">
										<p className="price">
											<s>$158.07</s>{" "}
										</p>
										<p className="price">${ParamData?.unit_price}</p>
									</div>
									<div style={{ width: "2rem" }}>
										<Heart
											inactiveColor={"white"}
											isActive={active}
											onClick={() => AddWishListHandler()}
											animationScale={1.25}
											style={{ marginBottom: "1rem" }}
										/>
									</div>
								</div>

								<div>
									<Rating onClick={handleRating} ratingValue={rating}
										// size={28}
										// readonly={true}
										// ratingValue={60}
										//   ratingValue={
										//     Math.round(item?.average) === 1
										//       ? "20"
										//       : Math.round(item?.average) === 2
										//       ? "40"
										//       : Math.round(item?.average) === 3
										//       ? "60"
										//       : Math.round(item?.average) === 4
										//       ? "80"
										//       : Math.round(item?.average) === 5
										//       ? "100"
										//       : null
										//   }
									/>
								</div>
								<hr style={{ color: "#ffff" }} />

								<p
									className="productDesc"
									dangerouslySetInnerHTML={{ __html: ParamData?.details }}
								/>

								<div className="row">
									<div className="col-md-6 col-sm-12 col-12 quantity-wrap">
										<div className="text">Quantity</div>
										<div className="qty-contaner">
											<button
												type="button"
												className="btn qty-btn"
												onClick={() => Increment(ParamData?.id)}
											>
												+
											</button>
											<p className="qty-num">{quantity}</p>
											<button
												type="button"
												className="btn qty-btn"
												onClick={() => Decrement(ParamData?.id)}
												disabled={quantity < 2}
											>
												-
											</button>
										</div>
									</div>
									<div className="col-md-6 col-sm-12 col-12 quantity-wrap">
										<div className="text">Size</div>
										<div>
											<select
												name="size"
												id="size"
												className="sizeSelect"
												onChange={(e) => {
													setSizeData(e.target.value);
												}}
											>
												<option value="S">S</option>
												<option value="M">M</option>
												<option value="L">L</option>
												<option value="XL">XL</option>
											</select>
										</div>
									</div>
								</div>

								<div className="mt-4 mb-4">
						
								<button
										type="button"
										className="btn AddBtn"
										onClick={() => AddToCartHandler(ParamData)}
									>
										<FontAwesomeIcon
											icon={faCartArrowDown}
											className="cart-icon mr-2"
										/>
										Add To Cart
									</button>
								
									<button
										type="button"
										className="btn sizeBtn"
										onClick={() => setIsOpenModal(true)}
									>
										Size Chart
									</button>
								</div>

								<div className="list">
									<p className="listItems">SKU:</p>{" "}
									<p className="listItems ml-2">NHFL1</p>
								</div>
								<div className="list">
									<p className="listItems">Product Type:</p>{" "}
									<p className="listItems  ml-2">Lorem ipsum</p>
								</div>
								<div className="list">
									<p className="listItems">Tag: </p>{" "}
									<p className="listItems  ml-2"> T.shirt</p>
								</div>
							</div>
						</div>
					</div>
					<div className="bottom-container">
						<section className="ClientsReviewsSec">
							<div className="container">
								<ul className="nav nav-tabs" id="myTab" role="tablist">
									<li className="nav-item">
										<a
											className="nav-link active"
											id="reviews-tab"
											data-toggle="tab"
											href="#reviews"
											role="tab"
											aria-controls="reviews"
											aria-selected="true"
										>
											Products Description
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="specification-tab"
											data-toggle="tab"
											href="#specification"
											role="tab"
											aria-controls="specification"
											aria-selected="false"
										>
											Reviews
										</a>
									</li>
								</ul>

								<div className="tab-content" id="myTabContent">
									<div
										className="tab-pane fade show active"
										id="reviews"
										role="tabpanel"
										aria-labelledby="reviews-tab"
									>
										<ProductDescriptionBox productDetail={ParamData?.details} />
									</div>
									<div
										className="tab-pane fade"
										id="specification"
										role="tabpanel"
										aria-labelledby="specification-tab"
									>
										<div className="review-container">
											<div>
												<AllReviews
													ReviewsDataRedux={ReviewsDataRedux}
													productReview={productReview}
													setProductReview={setProductReview}
													ParamData={ParamData}
													handlePageClick={handlePageClick}
													pageCount={pageCount}
												/>
											</div>
											<hr style={{ color: "#fff" }} />
											<div>
												<ReviewBox
													name={name}
													setName={setName}
													email={email}
													setEmail={setEmail}
													comment={comment}
													setComment={setComment}
													SubmitRating={SubmitRating}
													handleRating={handleRating}
													rating={rating}
													loading={loading}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<PercentOffAdds />
			<NewsLetter />
			<Footer />
			<SizeModal setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />
		</>
	);
}

export default ProductDetails;
