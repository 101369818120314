import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);
  return (
    <>
      <div className="sidebar">
        <ul>
          <li className={`${currentUrl?.[3] === "profile" ? "active" : null}`}>
            <Link to="/profile">My Profile</Link>
          </li>
          <li className={`${currentUrl?.[3] === "my-orders" ? "active" : null}`}>
            <Link to="/my-orders">My orders</Link>
          </li>
          <li className={`${currentUrl?.[3] === "wishlist" ? "active" : null}`}>
            <Link to="/wishlist"> My Wishlist</Link>
          </li>
          <li className={`${currentUrl?.[3] === "address-page" ? "active" : null}`}>
            <Link to="/address-page"> Address</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default SideBar;
