import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { avail } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function AddressModal(props) {
  const { setIsOpenModal, 
      isOpenModal,
      AddNewAddress,
      modalContact, setModalContact,
      modalZipCode, setModalZipCode,
      modalCity, setModalCity,
      modalAddress, setModalAddress,   
      modalCountry, setModalCountry,
      modalAddressType,setModalAddressType,
      modalContactPerson,setModalContactPerson,
      modalState, setModalState,
      modalLoading,
    } = props;
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="address-modal"
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeIcon"
            onClick={() => setIsOpenModal(false)}
          />
          <div className="container">
          <div className="row user-fields"> 
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Contact</p>
                      <input 
                        type="text"
                        placeholder="Contact" 
                        value={modalContact}
                        onChange={(e)=>setModalContact(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Zip Code</p>
                      <input 
                        type="text" 
                        placeholder="Zip Code" 
                        value={modalZipCode}
                        onChange={(e)=>setModalZipCode(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">City</p>
                      <input 
                        type="text" 
                        placeholder="City" 
                        value={modalCity}
                        onChange={(e)=>setModalCity(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Address Type</p>
                      <input 
                        type="text" 
                        placeholder="Address Type" 
                        value={modalAddressType} 
                        onChange={(e)=>setModalAddressType(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Address</p>
                      <input 
                        type="text" 
                        placeholder="Address" 
                        value={modalAddress} 
                        onChange={(e)=>setModalAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Country</p>
                      <input 
                        type="text" 
                        placeholder="Country" 
                        value={modalCountry}
                        onChange={(e)=>setModalCountry(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">state</p>
                      <input 
                        type="text" 
                        placeholder="Country" 
                        value={modalState}
                        onChange={(e)=>setModalState(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Contact Person</p>
                      <input 
                        type="text" 
                        placeholder="Country" 
                        value={modalContactPerson}
                        onChange={(e)=>setModalContactPerson(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4 " style={{textAlign: 'center'}}> 
                      <button 
                        type="button" 
                        className="btn saveBtn"
                        onClick={(e)=>AddNewAddress(e)}
                        disabled={modalLoading}
                      >
                        {modalLoading ? "Loading..." : "Save" }
                      </button>
                    </div>
                  </div>
          </div>
       
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddressModal;
