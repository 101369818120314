import React,{useEffect, useState} from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import AddressModal from "../../components/Modal/AddressModal";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import SideBar from "../../components/SideBar/SideBar";
import { p1 } from "../../constant";
import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PostAddress, PostChangePassword, UpdateProfile } from "../../network/Network";
import { EditProfileData } from "../../redux/actions/AuthActions";
import { ramdomImage } from "../../constant/ConstantFunction";
import ChangePasswordModal from "../../components/Modal/ChangePasswordModal";

function Profile() {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);

  const [isOpenModal, setIsOpenModal ] = useState(false)
  const [editProfile, setEditProfile ] = useState(false)
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [email, setEmail] = useState(userData?.email);
  const [firstName, setFirstName] = useState(userData?.f_name);
  const [lastName, setLastName] = useState(userData?.l_name);
  const [ contact, setContact ] = useState(userData?.phone);
  const [zipCode, setZipCode] = useState(userData?.zip);
  const [ city, setCity ] = useState(userData?.city);
  const [ gender, setGender ] = useState(userData?.gender);
  const [ country, setCountry ] = useState(userData?.country);
  const [ streetAddress, setStreetAddress ] = useState(userData?.street_address);
  
  const [checked, setChecked] = useState("");

  const [modalContact, setModalContact] = useState("")
  const [modalZipCode, setModalZipCode] = useState("")
  const [modalCity, setModalCity] = useState("")
  const [modalAddress, setModalAddress] = useState("")
  const [modalCountry, setModalCountry] = useState("")
  const [modalAddressType, setModalAddressType] = useState("")
  const [ modalContactPerson, setModalContactPerson] = useState("")
  const [ modalState, setModalState] = useState("")
  const [modalLoading, setModalLoading] = useState(false)
  

  const [isOpenModalPass, setIsOpenModalPass ] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [ modalBtn, setModalBtn ] = useState(false)
  

  const handleSelectCategory = (id) => {
    setChecked(id);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  const EditProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !contact ||
      !city ||
      !zipCode ||
      !gender
    ) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    let data = new FormData();
    data.append("f_name", firstName);
    data.append("l_name", lastName);
    data.append("phone", contact);
    data.append("street_address", streetAddress);
    data.append("city", city);
    data.append("zip", zipCode);
    data.append("gender", gender);
    data.append("image", newFile);
    console.log(data);
    UpdateProfile(data, Token)
      .then((res) => {
        setLoading(false);
        setUploadLoading(false);
        toast.success(res?.data?.message);
        console.log(res);
        dispatch(EditProfileData(res?.data?.data?.user));
        setEditProfile(!editProfile)
        // Navigate("/dashboard/profile");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setUploadLoading(false);
      });
  };


  const AddNewAddress = (e) => {
    setModalLoading(true)
    e.preventDefault();
    if (
      !modalContact ||
      !modalZipCode ||
      !modalCity ||
      !modalAddress ||
      !modalCountry ||
      !modalContactPerson ||
      !modalState
    ) {
      setModalLoading(false)
      toast.error("Please Enter All Field");
      return;
    }
    let data = new FormData();
    data.append("country", modalCountry);
    data.append("city", modalCity);
    data.append("address", modalAddress);
    data.append("phone", modalContact); 
    data.append("address_type", modalAddressType);
    data.append("zip", modalZipCode);
    data.append("contact_person_name", modalContactPerson);
    data.append("state", modalState);
    console.log(data);
    PostAddress(data, Token)
      .then((res) => {
        toast.success(res?.data?.message);
        console.log(res);
        setModalLoading(false)
        setIsOpenModal(false)
        // dispatch(EditProfileData(res?.data?.data?.user));
      })
      .catch((err) => {
        console.log(err);
        setModalLoading(false)
      });
  };


  const changePasswordHandler = (e) => {
    e.preventDefault();
    setModalBtn(true);
    if (
      !currentPassword ||
      !newPassword ||
      !confirmPassword 
    ) {
      setModalBtn(false)
      toast.error("Please Enter All Field");
      return;
    }
    if (newPassword !== confirmPassword ) {
      setModalBtn(false)
      toast.error("New Password and confirm Password are not same ");
      return;
    }
    let data = new FormData();
    data.append("current_password", currentPassword);
    data.append("new_password", newPassword);
    data.append("confirm_password", confirmPassword);
    console.log(data);
    PostChangePassword(data, Token)
      .then((res) => {
        setModalBtn(false);
        toast.success(res?.data?.message);
        setIsOpenModalPass(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        console.log(err);
        toast.error( err?.response?.data?.message)
        setModalBtn(false);
        setIsOpenModalPass(false);
      });
  };


  return (
    <>
      <Header 
          setChecked={setChecked}
      />
      <UrlHeader page={"Profile"}/>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Profile</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <div className="Profile">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="header">
                      <div className="flex-style">
                        <div className="profile-img">
                        {uploadLoading ? (
                            <img
                              src={fileupload}
                              className="image-fluid image-width"
                              alt=""
                            />
                          ) : (
                            <>
                              {userData?.image == null ? (
                                <img
                                  src={`${ramdomImage(
                                    `${userData?.first_name}{""}${userData?.last_name}`
                                  )}`}
                                  className="image-fluid image-width"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={`${
                                    fileupload ? fileupload : userData?.profile_url
                                  }`}
                                  className="image-fluid image-width"
                                  alt=""
                                />
                              )}
                            </>
                          )}
                          {/* <img
                            src={p1}
                            alt=""
                            className="image-fluid image-width"
                          /> */}
                        </div>
                        <div className="edit-container">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="edit-pen-icon"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            multiple="false"
                          />
                        </div>
                      </div>

                      <div>
                        <p className="name">{userData?.f_name}{" "}{userData?.l_name}</p>
                        <p className="sub-title">Washington DC</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="save-container">
                      {editProfile ? (
                         <button type="button" className="btn saveBtn btn-primary"
                            onClick={(e) => EditProfile(e)}
                            disabled={loading}
                         >
                        {loading ? "Loading.." : "Save"} 
                       </button>
                      ) : (
                        <button type="button" className="btn saveBtn btn-primary"
                          onClick={()=>setEditProfile(!editProfile)}
                        >
                       Edit
                      </button>
                      )}
                     
                    </div>
                  </div>

                  <div className="row user-fields">
                    {
                      editProfile ? (
                        <>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">First Name</p>
                      <input 
                        type="text" 
                        placeholder="First Name" 
                        value={firstName}
                        onChange={(e)=>setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Last Name</p>
                      <input 
                        type="text" 
                        placeholder="Last Name" 
                        value={lastName}
                        onChange={(e)=>setLastName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Email Address</p>
                      <input 
                        type="text" 
                        placeholder="Email Address" 
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Contact</p>
                      <input 
                        type="text" 
                        placeholder="Contact" 
                        value={contact}
                        onChange={(e)=>setContact(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Zip Code</p>
                      <input 
                        type="text" 
                        placeholder="Zip Code" 
                        value={zipCode}
                        onChange={(e)=>setZipCode(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">City</p>
                      <input 
                        type="text" 
                        placeholder="City" 
                        value={city}
                        onChange={(e)=>setCity(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Gender</p>
                      <input 
                        type="text" 
                        placeholder="Gender" 
                        value={gender}
                        onChange={(e)=>setGender(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Country</p>
                      <input 
                        type="text" 
                        placeholder="Country"
                        value={country} 
                        onChange={(e)=>setCountry(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Address</p>
                      <input 
                        type="text" 
                        placeholder="Country"
                        value={streetAddress} 
                        onChange={(e)=>setStreetAddress(e.target.value)}
                      />
                    </div>
                   
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <p className="input-label">Add New Address</p>
                      <button type="button" className="add-Address" 
                      onClick={()=>setIsOpenModal(true)}
                      >
                        <div>Address</div>
                          <FontAwesomeIcon  
                            icon={faPlus}
                            className="address-icon"
                          />
                      </button>
                    </div>
                   
                        </>
                      ) : (
                        <>
                        <div className="col-md-12 col-sm-12 col-12 mt-4">
                          <p className="sub-title2" 
                            onClick={(e)=>setIsOpenModalPass(true)}
                            >Change Password</p>
                        </div>
                  <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">First Name</p>
                        <p className="info">{userData?.f_name}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">Last Name</p>
                        <p className="info">{userData?.l_name}</p>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">Email Address</p>
                        <p className="info">{userData?.email}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">Contact</p>
                        <p className="info">{userData?.phone}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">Zip Code</p>
                        <p className="info">{userData?.zip}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">City</p>
                        <p className="info">{userData?.city}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">Address</p>
                        <p className="info">{userData?.street_address}</p>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-12 mt-4">
                      <div className="info-container">
                        <p className="input-label">Country</p>
                        <p className="info">{userData?.country}</p>
                      </div>
                    </div>
                  
                        </>
                      )
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />

      <AddressModal 
        setIsOpenModal={setIsOpenModal} 
        isOpenModal={isOpenModal} 

        AddNewAddress={AddNewAddress}
        modalContact={modalContact}
        setModalContact={setModalContact}
        modalZipCode={modalZipCode}
        setModalZipCode={setModalZipCode}
        modalCity={modalCity}
        setModalCity={setModalCity}
        modalAddress={modalAddress}
        setModalAddress={setModalAddress}
        modalCountry={modalCountry} 
        setModalCountry={setModalCountry}
        modalAddressType={modalAddressType}
        setModalAddressType={setModalAddressType}
        modalContactPerson={modalContactPerson}
        setModalContactPerson={setModalContactPerson}
        modalState={modalState} 
        setModalState={setModalState}
        modalLoading={modalLoading}
      />

      <ChangePasswordModal 
        setIsOpenModalPass={setIsOpenModalPass} 
        isOpenModalPass={isOpenModalPass} 

        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword} 
        setConfirmPassword={setConfirmPassword}
        changePassword={changePasswordHandler}
        modalBtn={modalBtn}
      />

    </>
  );
}

export default Profile;
