import { faCamera, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import SideBar from "../../components/SideBar/SideBar";
import { p1 } from "../../constant";

function AddressPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <UrlHeader page={"Address"}/>
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="page-name">My Address</h2>
              <SideBar />
            </div>
            <div className="col-md-9">
              <div className="Profile">
                <div className="row">
                  {/* <div className="col-md-6 col-sm-6 col-6">
                    <div className="header">
                      <div className="flex-style">
                        <div className="profile-img">
                          <img
                            src={p1}
                            alt=""
                            className="image-fluid image-width"
                          />
                        </div>
                      </div>

                      <div>
                        <p className="name">John Doe</p>
                        <p className="sub-title">Washington DC</p>
                      </div>
                    </div>
                  </div> */}
                

                  <div className="row user-fields">
                    <div className="col-md-6 col-sm-12 col-12 mt-4 address-space">
                      <p className="input-label ">Country:</p>
                      <p className="input-label details " >United States</p>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4 address-space">
                    <p className="input-label ">State:</p>
                      <p className="input-label details ">Texas</p>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4 address-space">
                    <p className="input-label ">Address:</p>
                      <p className="input-label details ">10515 Fox Ave Fairdale, Kentucky(KY), 40118</p>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mt-4 address-space">
                      <p className="input-label ">Phone:</p>
                      <p className="input-label details ">+44-123-456-789</p>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default AddressPage;
