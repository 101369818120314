import React, { useEffect, useState } from "react";
import ProductCard from "../../components/Card/ProductCard";
import MuiCategories from "../../components/Categories/MuiCategories";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { arrival1, arrival2, arrival3 } from "../../constant";
import UrlHeader from "../../components/Header/UrlHeader";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import PriceFilter from "../../components/Price Range/PriceFilter";
import {
	GetAllProducts,
	GetProductsFilter,
	PostPriceFilter,
	SearchProducts,
} from "../../network/Network";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { toast } from "react-toastify";

function AllProductPage() {
	const CategoriesRedux = useSelector(
		(state) => state.AuthReducer.categoriesData,
	);
	const newArrivalData = [
		{
			id: 1,
			img: arrival1,
			title: "Your Product Name",
			price: "$130.00 ",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
		},
		{
			id: 2,
			img: arrival2,
			title: "Your Product Name",
			price: "$130.00 ",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
		},
		{
			id: 3,
			img: arrival3,
			title: "Your Product Name",
			price: "$130.00 ",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
		},
		{
			id: 4,
			img: arrival2,
			title: "Your Product Name",
			price: "$130.00 ",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
		},
		{
			id: 5,
			img: arrival1,
			title: "Your Product Name",
			price: "$130.00 ",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
		},
		{
			id: 6,
			img: arrival2,
			title: "Your Product Name",
			price: "$130.00 ",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
		},
	];
	const categoriesData = [
		{
			id: 1,
			name: "Girls Wear (17)",
		},
		{
			id: 2,
			name: "Boys Wear (14)",
		},
		{
			id: 3,
			name: "Seasonal (12)",
		},
		{
			id: 4,
			name: "Accessories (15)",
		},
		{
			id: 5,
			name: "Boys Wear (14)",
		},
		{
			id: 6,
			name: "Seasonal (12)",
		},
		{
			id: 7,
			name: "Accessories (15)",
		},
	];
	const [highToLow, setHighToLow] = useState(null);
	const [spinLoad, setSpinLoad] = useState(false);
	const [startPrice, setStartPrice] = useState("");
	const [endPrice, setEndPrice] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();
	const [productsData, setProductsData] = useState([]);
	const [checked, setChecked] = useState("");

	const [searchSpinLoad, setSearchSpinLoad] = useState(false);
	const [productsSearch, setProductsSearch] = useState("");

	const handlePageClick = async (data) => {
		console.log(data.selected);
		setCurrentPage(data?.selected + 1);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleSelectCategory = (id) => {
		setChecked(id);
	};

	useEffect(() => {
		setSpinLoad(true);
		GetAllProducts(currentPage, highToLow)
			.then((res) => {
				console.log(res);
				setProductsData(res?.data?.data?.products?.data);
				// setProductImages(res?.data?.data?.products?.data);
				const total = res?.data?.data?.products?.total;
				const limit = res?.data?.data?.products?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				console.log(err);
				setSpinLoad(false);
			});
	}, [currentPage, highToLow]);

	// Filter by categories id
	useEffect(() => {
		setSpinLoad(true);
		GetProductsFilter(currentPage, checked)
			.then((res) => {
				console.log(res);
				setProductsData(res?.data?.data?.data);
				const total = res?.data?.data?.total;
				const limit = res?.data?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				console.log(err);
				setSpinLoad(false);
			});
	}, [currentPage, checked]);

	const PriceFilterHandler = (e) => {
		e.preventDefault();
		setSpinLoad(true);
		let data = {
			start: startPrice,
			end: endPrice,
			id: checked,
		};
		PostPriceFilter(currentPage, data)
			.then((res) => {
				console.log("price", res);
				setProductsData(res?.data?.data?.products?.data);
				const total = res?.data?.data?.products?.total;
				const limit = res?.data?.data?.products?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				console.log(err);
				setSpinLoad(false);
			});
	};

	const SubmitSearch = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			setSpinLoad(true);
			setSearchSpinLoad(true);
			if (!productsSearch) {
				toast.error("Please enter Product Name");
				setSearchSpinLoad(false);
				setSpinLoad(false);
				return;
			}
			SearchProducts(productsSearch)
				.then((res) => {
					console.log(res);
					setProductsData(res?.data?.data?.products?.data);
					setSearchSpinLoad(false);
					setSpinLoad(false);
				})
				.catch((err) => {
					console.log(err?.response?.data);
					toast.error(err?.response?.data?.message);
					setSearchSpinLoad(false);
					setSpinLoad(false);
				});
		}
	};

	return (
		<>
			<Header setChecked={setChecked} />
			<UrlHeader page={"Products"} />
			<div className="container">
				<div className="row category-margin">
					<div className="col-md-3">
						<div className="categories-container">
							<div className="header">
								<p className="title">Categories</p>
							</div>
							{CategoriesRedux?.map((item, index) => {
								return (
									<MuiCategories
										item={item}
										index={index}
										handleSelectCategory={handleSelectCategory}
									/>
								);
							})}
						</div>
						<PriceFilter
							startPrice={startPrice}
							setStartPrice={setStartPrice}
							endPrice={endPrice}
							setEndPrice={setEndPrice}
							PriceFilterHandler={PriceFilterHandler}
						/>
					</div>
					<div className="col-md-9">
						<div>
							<div className="header-result">
								<div>
									<p className="result-text">
										Showing {productsData?.length} results
									</p>
								</div>
								<div className="searchBox-product">
									<input
										type="text"
										placeholder="Search Product"
										value={productsSearch}
										onChange={(e) => setProductsSearch(e.target.value)}
										onKeyDown={SubmitSearch}
									/>
									{/* <button onClick={(e) => SubmitSearch(e)}>
                      <i
                         className="fa fa-search"
                        aria-hidden="true"
                      ></i>
                    </button> */}
									<div className="search-icon">
										<i class="fa fa-search" aria-hidden="true"></i>
									</div>
								</div>
								<div>
									<select
										name="filter"
										id="filter"
										className="sizeSelect"
										onChange={(e) => {
											setHighToLow(e.target.value);
										}}
									>
										<option value=""> Default Sorting</option>
										<option value="high_to_low">Hign To Low</option>
										<option value="low_to_high">Low To High</option>
									</select>
								</div>
							</div>

							<div className="row">
								{spinLoad ? (
									<>
										<div className="loader-container">
											<SpinnerCircular size="80px" color="#d60012" />
										</div>
									</>
								) : (
									<>
										{productsData?.length > 0 ? (
											<>
												{productsData?.map((item, index) => {
													return (
														<>
															<div className="col-md-4 product-card">
																<ProductCard item={item} index={index} />
															</div>
														</>
													);
												})}
											</>
										) : (
											<>
												<p>Product Not Found</p>
											</>
										)}
									</>
								)}
							</div>

							<div className="pagination-container mt-5">
								<ReactPaginate
									// renderOnZeroPageCount={null}
									previousLabel="<<"
									nextLabel=">>"
									breakLabel={"..."}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={handlePageClick}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<PercentOffAdds />
			<NewsLetter />
			<Footer />
		</>
	);
}

export default AllProductPage;
