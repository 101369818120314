import React from "react";
import { useLocation } from "react-router-dom";

function ProductDescriptionBox(props) {
  const location = useLocation();
  const { productDetail } = props;
  const ParamData = location?.state?.data;

  return (
    <>
      <div className="detail-tab">
        {/* <h4 className=" title font-25 fw-600">Paragraph text</h4> */}

        <p className="desc"dangerouslySetInnerHTML={{ __html: ParamData?.details }} />
          
                  
      </div>
    </>
  );
}

export default ProductDescriptionBox;
