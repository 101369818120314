import moment from "moment";
import React from "react";
import { Rating } from "react-simple-star-rating";
import { p1 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";

function SingleReviewCard(props) {
  const { item,index } = props;
  console.log("test", item);


  
  return (
    <>
         <div className="mainReview mt-2">
              <div className="brieflyReview">
                <div className="img-box">
                  <figure>
                  {/* {item?.customer?.profile_url == null ? (
                <img
                  src={`${ramdomImage(`${item?.customer?.full_name}}`)}`}
                  alt=""
                  className="image-fluid image-width"
                />
              ) : (
                <img
                  src={item?.customer?.profile_url}
                  alt=""
                  className="image-fluid image-width"
                />
              )} */}
                    <img src= {item?.customer?.profile_url} alt="" />
            
                  </figure>
                </div>
                <div className="contentDv">
                  <div className="info">
                    <h6 className="name" style={{ fontSize: "12px" }}>
                        {item?.customer?.full_name}
                    </h6>
                    <div className="spacer">-</div>
                    <h6 className="date" style={{ fontSize: "12px" }}>
                        {moment(item?.customer?.created_at).format(
                        "MMMM Do YYYY, h:mm a"
                         )}
                    </h6>
                  </div>
                  <div className="descrip">
                    <p className="m-0">
                    {item?.comment}
                    </p>
                    <div className="rating mb-2">
                <div className="stars">
                  <Rating
                    size={18}
                    readonly={true}
                    ratingValue={
                      Math.round(item?.rating) === 1
                        ? "20"
                        : Math.round(item?.rating) === 2
                        ? "40"
                        : Math.round(item?.rating) === 3
                        ? "60"
                        : Math.round(item?.rating) === 4
                        ? "80"
                        : Math.round(item?.rating) === 5
                        ? "100"
                        : null
                    }
                  />

                  {/* <div className="star-count">
                    <span>{item?.rating}</span>
                  </div> */}
                </div>
               
              </div>

                    {/* <div className="rating">
                      <div className="rply-btn">
                        <a href="#!">Reply</a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="replyDv">
                <div className="brieflyReview">
                  <div className="img-box">
                    <figure><img src="img/client.png" alt="" /></figure>
                  </div>
                  <div className="contentDv">
                    <div className="info">
                      <h6 className="name" style={{ fontSize: "12px" }}>
                        Joeby Ragpa
                      </h6>
                      <div className="spacer">-</div>
                      <h6
                        className="date"
                        style={{ color: "#909090", fontSize: "12px" }}
                      >
                        12 April, 2014 at 16:50
                      </h6>
                    </div>
                    <div className="descrip">
                      <p>
                        We possess within us two minds. So far I have written
                        only of the conscious mind. I would now like to
                        introduce you to your second mind , the hidden and
                        mysterious subconscious.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="brieflyReview">
                        <div className="img-box">
                            <figure><img src="img/client.png" alt="" /></figure>
                        </div>
                        <div className="contentDv">
                            <div className="info">
                                <h6 className="name" style="font-size:12px;">Joeby Ragpa</h6>
                                <div className="spacer">-</div>
                                <h6 className="date" style="color:#909090; font-size:12px;">12 April, 2014 at 16:50</h6>
                            </div>
                            <div className="descrip">
                                <p>
                                    We possess within us two minds. So far I have written only of the conscious mind. I
                                    would now like to introduce you to your
                                </p>
                            </div>
                        </div>
                    </div>
                  
                    <div className="brieflyReview">
                        <div className="img-box">
                            <figure><img src="img/client.png" alt="" /></figure>
                        </div>
                        <div className="contentDv">
                            <div className="info">
                                <h6 className="name" style="font-size:12px;">Joeby Ragpa</h6>
                                <div className="spacer">-</div>
                                <h6 className="date" style="color:#909090; font-size:12px;">12 April, 2014 at 16:50</h6>
                            </div>
                            <div className="descrip">
                                <p>
                                    We possess within us two minds. So far I have written only of the conscious mind. I
                                    would now like to introduce you to your second mind,
                                </p>
                            </div>
                        </div>
                    </div>
              </div> */}
            </div>
    </>
  );
}

export default SingleReviewCard;
