import React from 'react'

function OrderSummary(props) {
    const {promoCode,setPromoCode, CheckOutData} = props;
        console.log(CheckOutData)
  return (
    <>
     <p className="mt-4rem font-roboto-medium f-20 mb-4">
        Order Summary
    </p>
        {/* <div className="d-flex mb-3 med-dflex">
            <input
                type="text"
                placeholder="Enter Promo Code"
                className="form-control me-3 f-15 font-roboto-medium promo_field mt-2"
                value={promoCode}
                onChange={(e)=>setPromoCode(e.target.value)}
            />
            <button 
                className="view_more btn font-15 bt-hover-color f-18 fw-bold mt-2"
            >
                Apply
            </button>
        </div> */}
            <div className="order-box p-4">
                <div className="d-price">
                    <p className="font-roboto-bold f-17">Product Details</p>
                </div>
                <div className='summary-inner'>
                {CheckOutData?.CartData?.map((item,index)=>{
                    return(
                        <div className="d-price align-center p-price mb-4" key={index}>
                        <div className="d-flex align-center res-down-product">
                        <img
                            className="product_detail_image "
                            // className='image-fluid image-width'
                            src={item?.productitem?.thumbnail_url}
                            alt="product_image"
                        />
                          {/* <img
                            className="product_detail_image"
                            src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
                            alt="product_image"
                        /> */}
                        <p className="ms-3 f-13 font-mon-semibold res-set-lpt">
                            {item?.productitem?.name}
                        </p>
                        </div>
                        <p className="f-17 font-mon-semibold">${item?.productitem?.unit_price}</p>
                    </div>
                    )
                })}
                </div>
               
                
                {/* <div className="d-price align-center p-price mb-4">
                    <div className="d-flex align-center res-down-product">
                    <img
                        className="product_detail_image"
                        src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
                        alt="product_image"
                    />
                    <p className="ms-3 f-13 font-mon-semibold res-set-lpt">
                        Your Product Name
                    </p>
                    </div>
                    <p className="f-17 font-mon-semibold">$158.07</p>
                </div> */}
                <div className="d-price">
                    <p className="f-17 font-roboto-regular">Item Total :</p>
                    <p className="f-17 font-roboto-regular">${CheckOutData?.total}</p>
                </div>
                <div className="d-price">
                    <p className="f-17 font-roboto-regular">
                        Shipment & Delivery :
                    </p>
                    <p className="f-17 font-roboto-regular">$0</p>
                </div>
                <div className="d-price brdr-btm-light pb-3">
                    <p className="f-17 font-roboto-regular">
                        Promo Applied :
                    </p>
                    <p className="f-17 font-roboto-regular">-$
                        {CheckOutData?.couponData?.discount
                        ? CheckOutData?.couponData?.discount
                        : "0"}
                    </p>
                </div>
                <div className="d-price mt-4">
                    <p className="f-20 font-roboto-bold">Order Total :</p>
                    <p className="f-20 font-roboto-bold">$
                    {CheckOutData?.total >=
                        CheckOutData?.couponData?.min_purchase ? (
                    <>
                      {`${CheckOutData?.total}` -
                        `${CheckOutData?.couponData?.discount}`}
                    </>
                  ) : (
                    CheckOutData?.total
                  )}
                    </p>
                </div>
            </div>
    </>
  )
}

export default OrderSummary