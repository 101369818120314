export const ALERTS = "ALERTS";
export const SET_ALERTS = "SET_ALERTS";

export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_REQUEST";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const USER_TOKEN = "USER_TOKEN";

export const ALL_CATEGORIES = "ALL_CATEGORIES";
export const WISH_lIST = "WISH_lIST";
export const REMOVE_WISH = "REMOVE_WISH";

export const HOT_DEALS = "HOT_DEALS";

export const ADD_TO_CART = "ADD_TO_CART";
export const CART_CLEAR = "CART_CLEAR";

export const SOCIAL_MEDIA = "SOCIAL_MEDIA";

export const COMPANYINFO = "COMPANYINFO";

export const DELETE_CART_ITEM = "DELETE_CART_ITEM";

export const INCREMENT_QUANTITY = "INCREMENT_QUANTITY";
export const DECREMENT_QUANTITY = "DECREMENT_QUANTITY";

export const PRODUCT_REVIEWS = "PRODUCT_REVIEWS";

export const BRANDS = "BRANDS";
export const ADS = "ADS";
export const COUNTRY = "COUNTRY";

export const TOP_PRODUCTS = "TOP_PRODUCTS";
export const BLOGS = "BLOGS";


// end

export const EDIT_PROFILE = "EDIT_PROFILE";
