// Define api end points here

export const AUTH = "/auth";
export const SIGNUP = "/register";
export const LOGIN = "/login";
export const CATEGORIES = "/categories";
export const FAQ = "/faq";
export const CONTACTUS = "/contactUs";
export const NEWSLETTER = "/newsletter";
export const COMPANY_INFORMATION = "/company-information";
export const CUSTOMER = "/customer";
export const UPDATE_PROFILE = "/update-profile";
export const PRODUCTS = "/products";
export const LATEST = "/latest";
export const STATUS = "?status";
export const WISHLIST = "/wish-list";
export const ADD = "/add";
export const REMOVE = "/remove";
export const PRODUCT_ID = "?product_id";
export const ORDER = "/order";
export const LIST = "/list";
export const BLOGS = "/blogs";
export const REVIEWS = "/reviews";
export const SUBMIT = "/submit";

export const GET_PAGE = "/get_pages";


// ---- 




export const HOTDEALS = "/hot-deals";

export const RELATED_PRODUCTS = "/related-products";
export const TRADING = "/trading";


export const PLACE = "/place";
export const TRACK = "/track";

export const REMOVE_ALL = "/remove_all";
export const CUSTOMER_ID = "?customer_id";



export const SOCIAL_MEDIA = "/social-media";
export const LINKS = "/links";

export const COUPON = "/coupon";
export const APPLY = "/apply";
export const CODE = "?code";

export const SEARCH = "/search";
export const NAME = "?name";

export const BRANDS = "/brands";
export const PRICE = "/price";
export const START = "?start";
export const END = "end";

export const BANNERS = "/banners";
export const BANNER_TYPE = "?banner_type";


export const ADS = "/ads";


export const COUNTRY = "/country";
export const FORGOT_PASSWORD = "/forgot-password";

export const RESET_PASSWORD = "/reset-password";

export const TOP_PRODUCTS = "/top-products";
export const PAGE = "?page";

export const TYPE = "?type";

export const QUESTION_STORE = "/QuestionStore";
export const GETOURTEAM = "/getOurTeam";

export const STORE_USER_ADDRESS = "/storeUserAddress";

export const CHANGE_PASSWORD = "/changePassword";




// end

// == End ==
