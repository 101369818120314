import React, { useEffect, useState } from "react";
import ProductCard from "../../components/Card/ProductCard";
import MuiCategories from "../../components/Categories/MuiCategories";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { arrival1, arrival2, arrival3 } from "../../constant";
import UrlHeader from "../../components/Header/UrlHeader";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import PriceFilter from "../../components/Price Range/PriceFilter";
import { GetHotDeals, GetHotProductFilter, PostPriceFilter } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { HotDealsData } from "../../redux/actions/AuthActions";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function HotDeals() {
  const dispatch = useDispatch()
  const newArrivalData = [
    {
      id: 1,
      img: arrival1,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 2,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 3,
      img: arrival3,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 4,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 5,
      img: arrival1,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 6,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 7,
      img: arrival3,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 8,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 9,
      img: arrival1,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
  ];
  const categoriesData = [
    {
      id: 1,
      name: "Girls Wear (17)",
    },
    {
      id: 2,
      name: "Boys Wear (14)",
    },
    {
      id: 3,
      name: "Seasonal (12)",
    },
    {
      id: 4,
      name: "Accessories (15)",
    },
    {
      id: 5,
      name: "Boys Wear (14)",
    },
    {
      id: 6,
      name: "Seasonal (12)",
    },
    {
      id: 7,
      name: "Accessories (15)",
    },
  ];
  const CategoriesRedux = useSelector(
    (state) => state.AuthReducer.categoriesData
  );

  console.log(CategoriesRedux)
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [checked, setChecked] = useState("");
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsData, setProductsData] = useState([]);
  const [highToLow, setHighToLow] = useState();

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

    // Get Hot Deals
    useEffect(() => {
      setSpinLoad(true);
      GetHotDeals(currentPage, highToLow)
        .then((res) => {
          console.log(res);
          setProductsData(res?.data?.data?.products?.data);
          // setProductImages(res?.data?.data?.products?.data);
          dispatch(HotDealsData(res?.data?.data?.products?.data));
          const total = res?.data?.data?.products?.total;
          const limit = res?.data?.data?.products?.per_page;
          setPageCount(Math.ceil(total / limit));
          setSpinLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    }, [currentPage, highToLow]);

    // == Hot Deal Filter By Categories ==
    useEffect(() => {
      setSpinLoad(true);
      GetHotProductFilter(currentPage, highToLow, checked)
        .then((res) => {
          console.log(res);
          setProductsData(res?.data?.data?.data);
          const total = res?.data?.data?.total;
          const limit = res?.data?.data?.per_page;
          setPageCount(Math.ceil(total / limit));
          setSpinLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    }, [currentPage, highToLow, checked]);

    // == Price Filter ==
    const PriceFilterHandler = (e) => {
      e.preventDefault();
      setSpinLoad(true);
      let data = {
        start: startPrice,
        end: endPrice,
        id: checked,
      };
      PostPriceFilter(currentPage, data)
        .then((res) => {
          console.log("price", res);
          setProductsData(res?.data?.data?.products?.data);
          const total = res?.data?.data?.products?.total;
          const limit = res?.data?.data?.products?.per_page;
          setPageCount(Math.ceil(total / limit));
          setSpinLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    };

  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"Hot Deals"}/>
      <div className="container">
        <div className="row category-margin">
          <div className="col-md-3">
            <div className="categories-container">
              <div className="header">
                <p className="title">Categories</p>
              </div>
              {CategoriesRedux?.map((item, index) => {
                return (
                  <MuiCategories 
                    item={item} 
                    index={index} 
                    handleSelectCategory={handleSelectCategory}
                  />
                )
              })}
            </div>
            <PriceFilter
              startPrice={startPrice}
              setStartPrice={setStartPrice}
              endPrice={endPrice}
              setEndPrice={setEndPrice}
              PriceFilterHandler={PriceFilterHandler}
            />
          </div>
          <div className="col-md-9">
            <div>
              <div className="header-result">
                <div>
                  <p className="result-text">Showing 1–16 of 17 results</p>
                </div>
                <div>
                  <select 
                      name="filter" 
                      id="filter" 
                      className="sizeSelect"
                      onChange={(e) => {
                        setHighToLow(e.target.value);
                      }}
                      >
                      <option value=""> Default Sorting</option>
                      <option value="high_to_low">Hign To Low</option>
                      <option value="low_to_high">Low To High</option>
                    </select>
                </div>
              </div>

              <div className="row">
                {
                  spinLoad ? (
                    <>
                      <div className="loader-container">
                        <SpinnerCircular size="80px" color="#d60012" />
                      </div>
                    </>
                  ) : (
                    <>
                     {productsData?.length > 0 ? (
                       <>
                          {productsData?.map((item, index) => {
                            return (
                              <>
                                <div className="col-md-4 product-card">
                                  <ProductCard item={item} index={index} />
                                </div>
                              </>
                            );
                          })}
                       </>
                      ) : (
                        <>
                           <p>No Product Found</p>
                        </>
                      ) }
                    </>
                  )
                }
                <div className="pagination-container mt-5">
                <ReactPaginate
                  // renderOnZeroPageCount={null}
                  previousLabel="<<"
                  nextLabel=">>"
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <PercentOffAdds />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default HotDeals;
