import React, { useState } from "react";
import "../../assets/css/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPhone,
	faMagnifyingGlass,
	faHeart,
	faCartShopping,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
	faTwitter,
	faLinkedinIn,
	faInstagram,
	faFacebookSquare,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Update_logo, cartimg, headlogo } from "../../constant";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ramdomImage } from "../../constant/ConstantFunction";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/AuthActions";

function Header(props) {
	const { setChecked, checked } = props;
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const CategoriesRedux = useSelector(
		(state) => state.AuthReducer.categoriesData,
	);
	const SocialMediaRedux = useSelector(
		(state) => state.AuthReducer.socialMedia,
	);
	const CartReduxData = useSelector((state) => state.CartReducer.cartData);
	const isLogin = useSelector((state) => state.AuthReducer.isLogin);
	const userData = useSelector((state) => state.AuthReducer.users);
	const [collapse, setCollapse] = useState(false);
	const [loading, setLoading] = useState(false);

	const toggleCollapse = () => {
		console.log("collapse", collapse);
		setCollapse(!collapse);
	};

	const LogoutHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		setTimeout(async () => {
			setLoading(false);
			let x = await dispatch(logout());
			// Navigate("/Signin");
			window.location.href = "/Signin";
		}, 200);
	};

	return (
		<>
			<div>
				{/* Nav top section   */}
				<section className="nav-top">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-6">
								<div className="nav-para">
									<p>
										<FontAwesomeIcon className="forcolor" icon={faPhone} /> :
										(123) 456-7890
									</p>
								</div>
							</div>

							<div className="col-md-6 col-sm-6 col-6">
								<div className="nav-ul">
									<ul>
										{/* {SocialMediaRedux?.map((item,index)=>{
                      return(
                        <li>
                          <a href={item?.link}>
                            <FontAwesomeIcon
                              className="forcolor"
                              icon={faFacebookSquare}
                            />
                          </a>
                        </li>
                      )
                    })} */}
										<li>
											<a href="">
												<FontAwesomeIcon
													className="forcolor"
													icon={faFacebookSquare}
												/>
											</a>
										</li>
										<li>
											<a href="">
												<FontAwesomeIcon
													className="forcolor"
													icon={faYoutube}
												/>
											</a>
										</li>
										<li>
											<a href="">
												<FontAwesomeIcon
													className="forcolor"
													icon={faTwitter}
												/>
											</a>
										</li>
										<li>
											<a href="">
												<FontAwesomeIcon
													className="forcolor"
													icon={faInstagram}
												/>
											</a>
										</li>
										<li>
											<a href="">
												<FontAwesomeIcon
													className="forcolor"
													icon={faLinkedinIn}
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="header nav-bottom">
					<div className="container">
						<nav className="navbar-dark navbar-expand-md navigation-clean-search ">
							<div className="row">
								<div className="col-md-2">
									<Link className="navbar-brand navbar-left" to="/">
										<div className="head-logo">
											<img
												// width="200"
												src={Update_logo}
												// src={headlogo}
												target="_self"
												className="logo-width"
												alt=""
											/>
										</div>
									</Link>
								</div>

								<div className="col-md-8">
									<button
										className="navbar-toggler"
										type="button"
										onClick={toggleCollapse}
									>
										<span className="sr-only">Toggle navigation</span>
										<span className="navbar-toggler-icon"></span>
									</button>
									<div
										className={
											"collapse navbar-collapse right-box for-justiy-center-nav" +
											(collapse && "show")
										}
										id="navbarNavDropdown"
									>
										<ul className="nav navbar-nav ">
											<li className="nav-item" role="presentation">
												<Link to="/" className="nav-link">
													Home
												</Link>
											</li>

											<li className="nav-item dropdown">
												<Link
													to="/all-product-page"
													className="nav-link dropdown-toggle custom-toggle"
													id="navbarDropdownMenuLink"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Products
												</Link>
												<ul
													className="dropdown-menu"
													aria-labelledby="navbarDropdownMenuLink"
												>
													<li className="border-bottomStyle">
														<Link
															to="/hotdeals"
															className="dropdown-item header-customItems"
														>
															Hot Deals
														</Link>
													</li>
													{/* <li className="border-bottomStyle">
                        <Link to="/newarrivals" className="dropdown-item header-customItems"> New Arrivals</Link>
                      </li> */}

													{CategoriesRedux?.map((item, index) => {
														return (
															<>
																<li className="dropdown-submenu  border-bottomStyle">
																	<Link
																		to={`/products/${item?.slug}`}
																		className="dropdown-item dropdown-toggle header-customItems "
																		key={item?.id}
																	>
																		<span onClick={() => setChecked(item?.id)}>
																			{item?.name}
																		</span>
																	</Link>
																	<ul className="dropdown-menu">
																		{item?.childes?.map((childItem, index) => {
																			return (
																				<li
																					className="border-bottomStyle"
																					key={childItem?.id}
																				>
																					<Link
																						to={`/products/${item?.slug}/${childItem?.slug}`}
																						className="dropdown-item header-customItems"
																					>
																						<span
																							onClick={() =>
																								setChecked(childItem?.id)
																							}
																						>
																							{childItem?.name}
																						</span>
																					</Link>
																				</li>
																			);
																		})}
																	</ul>
																</li>
															</>
														);
													})}
													{/* <li className="dropdown-submenu  border-bottomStyle">
                        <Link to="/products" className="dropdown-item dropdown-toggle header-customItems ">
                          Boys Wear
                        </Link>
                      <ul className="dropdown-menu">
                        <li  className="border-bottomStyle">
                        <a className="dropdown-item header-customItems header-subCate">Boys Wear (17)</a>
                        </li>
                        <li  className="border-bottomStyle">
                          <Link to="/products" className="dropdown-item header-customItems">  Boys Wear 1</Link>
                        </li>
                        <li>
                          <Link to="/products" className="dropdown-item header-customItems" style={{borderBottom: 'unset'}}>  Boys Wear 2</Link>
                        </li>
                      </ul>
                      </li>

                      <li className="dropdown-submenu border-bottomStyle">
                        <Link to="/products" className="dropdown-item dropdown-toggle header-customItems">
                        Boys Wear
                        </Link>
                      <ul className="dropdown-menu">
                        <li  className="border-bottomStyle">
                        <a className="dropdown-item header-customItems header-subCate">Boys Wear (17)</a>
                        </li>
                        <li  className="border-bottomStyle">
                          <Link to="/products" className="dropdown-item header-customItems">  Boys Wear 1</Link>
                        </li>
                        <li>
                          <Link to="/products" className="dropdown-item header-customItems style={{borderBottom: 'unset'}}">  Boys Wear 2</Link>
                        </li>
                      </ul>
                      </li>
                      <li className="dropdown-submenu">
                        <Link to="/products" className="dropdown-item dropdown-toggle header-customItems" style={{borderBottom: 'unset'}}>
                        Boys Wear
                        </Link>
                      <ul className="dropdown-menu">
                        <li  className="border-bottomStyle">
                        <a className="dropdown-item header-customItems header-subCate">Boys Wear (17)</a>
                        </li>
                        <li  className="border-bottomStyle">
                          <Link to="/products" className="dropdown-item header-customItems">  Boys Wear 1</Link>
                        </li>
                        <li>
                          <Link to="/products" className="dropdown-item header-customItems" style={{borderBottom: 'unset'}}>  Boys Wear 2</Link>
                        </li>
                      </ul>
                      </li> */}
												</ul>
											</li>
											{/* <li className="nav-item dropdown">
                    <Link
                      to="/products"
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Products
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to="/hotdeals" className="dropdown-item">
                        Hot Deals
                      </Link>

                      <Link to="/newarrivals" className="dropdown-item">
                        New Arrivals
                      </Link>
                
                  </div>
                  </li> */}
											<li className="nav-item" role="presentation">
												<Link to="/aboutus" className="nav-link">
													About
												</Link>
											</li>
											<li className="nav-item" role="presentation">
												<Link to="/faqs" className="nav-link">
													FAQ's
												</Link>
											</li>
											<li className="nav-item" role="presentation">
												<Link to="/blog" className="nav-link">
													Blogs
												</Link>
											</li>
											<li className="nav-item" role="presentation">
												<Link to="/contact" className="nav-link">
													Contact
												</Link>
											</li>
											{/* <li className="nav-item p-2" role="presentation">
                    <input 
                      type="text"
                      />
                    <a href="#">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="social-color icon-style"
                      />
                    </a>
                  </li> */}
										</ul>
									</div>
								</div>

								<div className="col-md-2">
									<ul className="nav navbar-nav navbar-right">
										<li className="nav-item p-2" role="presentation">
											<Link to="/cart">
												<div className="cart-num">{CartReduxData?.length}</div>

												<FontAwesomeIcon
													icon={faCartShopping}
													className="social-color icon-style"
												/>
											</Link>
										</li>

										{isLogin ? (
											<>
												<li
													className="nav-item p-2 ij profile-header"
													role="presentation"
												>
													<a
														// to="/auth/profile"
														className="nav-link dropdown-toggle flex-style"
														id="navbarDropdown"
														role="button"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														<div
															className="profile"
															onClick={() => Navigate("/profile")}
														>
															<img
																src={`${ramdomImage(
																	`${userData?.f_name}${userData?.l_name}`,
																)}`}
																className="image-width"
																style={{ borderRadius: "50%" }}
															/>
														</div>
													</a>
													<div
														className="dropdown-menu"
														aria-labelledby="navbarDropdown"
													>
														<Link to="/profile" className="dropdown-item">
															<p
																className="ml-2 header-role  m-0"
																// onClick={(e) => LogoutHandler(e)}
																style={{ cursor: "pointer" }}
															>
																My Profile
															</p>
														</Link>

														<a className="dropdown-item">
															<p
																className="ml-2 header-role m-0"
																onClick={(e) => LogoutHandler(e)}
																style={{ cursor: "pointer" }}
															>
																Logout
															</p>
														</a>
													</div>
												</li>
											</>
										) : (
											<>
												<li className="nav-item p-2" role="presentation">
													<Link to="/signup">
														<FontAwesomeIcon
															icon={faUser}
															className="social-color icon-style"
														/>
													</Link>
												</li>
											</>
										)}
									</ul>
								</div>
							</div>
						</nav>
					</div>
				</section>
			</div>
			<Outlet />
		</>
	);
}

export default Header;

{
	/* <li class="dropdown-submenu">
<a class="test" tabindex="-1" href="#">New dropdown <span class="caret"></span></a>
<ul class="dropdown-menu">
  <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
  <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
  <li class="dropdown-submenu">
    <a class="test" href="#">Another dropdown <span class="caret"></span></a>
    <ul class="dropdown-menu">
      <li><a href="#">3rd level dropdown</a></li>
      <li><a href="#">3rd level dropdown</a></li>
    </ul>
  </li>
</ul>
</li> */
}

// .navbar-expand-md .navbar-nav .dropdown-menu {
//   right: 10%;
//   left: unset;
//   top: 59%;
// }
// li.ij .dropdown-toggle::after {
//   display: none;
// }
