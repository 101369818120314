import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { avail } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function SizeModal(props) {
  const { setIsOpenModal, isOpenModal } = props;
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="sizeChart-modal"
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeIcon"
            onClick={() => setIsOpenModal(false)}
          />
          <div className="row">
            <div className="col-md-3">
              <div className="image-box">
                <img src={avail} alt="" className="image-fluid image-width" />
              </div>
            </div>
            <div className="col-md-9">
              <table class="table table-striped table-dark">
                <thead>
                  <tr>
                    <th scope="col">Size</th>
                    <th scope="col">Length(cm)</th>
                    <th scope="col">Waist(cm)</th>
                    <th scope="col">Chest(cm)</th>
                    <th scope="col">Arm(cm)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" style={{backgroundColor: "#707070 "}}>S</th>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <th scope="row">M</th>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <th scope="row">L</th>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <th scope="row">XL</th>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default SizeModal;
