import "./App.css";
import "./assets/css/Style.css";
import "./assets/css/Testimonial.css";
import "./assets/css/About.css";
import "./assets/css/Products.css";
import "./assets/css/Profile.css";
import "./assets/css/Blog.css";
import "./assets/css/SizeChartModal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PublicRoutes from "./routes/PublicRoutes";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Alert from "./pages/Alert"
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { GetAds, GetAllCategories, GetHomeBanner, GetHotDeals, GetWishList, SocialMediaLinks } from "./network/Network";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesData, HotDealsData, SocialMediaStore, WishListData } from "./redux/actions/AuthActions";
import { Adsapi } from "./redux/actions/CartActions";

function App() {
  const dispatch = useDispatch()
  const Token = useSelector((state) => state.AuthReducer.token);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [highToLow, setHighToLow] = useState();  

    // Get All Categories
    useEffect(() => {
      GetAllCategories()
        .then((res) => {
          console.log(res);
          dispatch(CategoriesData(res?.data?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

  // Get Social Media Links
  useEffect(() => {
    SocialMediaLinks()
      .then((res) => {
        console.log("social",res);
        dispatch(SocialMediaStore(res?.data?.data?.social));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let data = {
      type: "all",
    };
    GetHomeBanner(data)
      .then((res) => {
        console.log("banner", res);
        let arr = [];
        for (let i = 0; i < res?.data.length; i++) {
          if (res?.data?.[i].banner_type === "Main Banner")
            arr.push(res?.data?.[i]);
        }
        // setBannerData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

        // // Get News
        // useEffect(() => {
        //   GetNews()
        //     .then((res) => {
        //       console.log(res);
        //       setNewsData(res?.data?.data?.blogs);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // }, []);

    // Get Hot Deals
    useEffect(() => {
      GetHotDeals(currentPage, highToLow)
        .then((res) => {
          console.log(res);
          dispatch(HotDealsData(res?.data?.data?.products?.data));
          const total = res?.data?.data?.products?.total;
          const limit = res?.data?.data?.products?.per_page;
          setPageCount(Math.ceil(total / limit));
        })
        .catch((err) => {
          console.log(err);
        });
    }, [currentPage, highToLow]);

      // Get ads
  useEffect(() => {
    GetAds()
      .then((res) => {
        console.log(res);
        // setAdsData(res?.data?.data?.ads);
        dispatch(Adsapi(res?.data?.data?.ads));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

    // Get WishList
    useEffect(() => {
      GetWishList(currentPage, Token)
        .then((res) => {
          console.log(res);
          dispatch(WishListData(res?.data?.data?.wishlist?.data));
          const total = res?.data?.data?.wishlist?.total;
          const limit = res?.data?.data?.wishlist?.per_page;
          setPageCount(Math.ceil(total / limit));
        })
        .catch((err) => {
          console.log(err);
        });
    }, [currentPage]);

  return (
    <>
      <PublicRoutes />
      <ToastContainer />
      <Alert />
    </>
  );
}

export default App;
