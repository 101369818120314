import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Login.css";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { login } from "../../redux/actions/AuthActions";

const Login = () => {
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [checked, setChecked] = useState("");
	const [values, setValues] = React.useState({
		password: "",
		showPassword: false,
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handlePasswordChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleSelectCategory = (id) => {
		setChecked(id);
	};

	const SignInHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (!email || !password) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			return;
		}
		let data = {
			email: email,
			password: password,
		};

		console.log(data);
		setTimeout(async () => {
			setLoading(false);
			let x = await dispatch(login(data));
			console.log("response login", x);
			if (x) {
				console.log(x);
				Navigate("/");
			}
		}, 600);
	};

	return (
		<>
			<Header setChecked={setChecked} />
			<UrlHeader page={"Sign In"} />
			<div className="login">
				<div className="dark_black_div">
					<div className="container">
						<div className="row pb-5">
							<div className="col-lg-12 text-center">
								<h3 className="mt-4rem font-roboto-bold f-31">
									Sign in to your Account
								</h3>
								<p className="f-16 font-roboto-light pad-res-lft-rght-login">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore.
								</p>
								<div className="container">
									<div className="row">
										<div className="offset-lg-1 col-lg-5 pe-5 text-start pe-res-login">
											<form>
												<p className="font-mon-semibold f-15 mt-5">Email</p>
												<input
													type="text"
													placeholder="Enter Your Email"
													id="email"
													className="form-control p-3 f-13 font-mon-medium rounded-0 signup-fld"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
												<div className="confirm-password-icon">
													<p className="font-mon-semibold f-15 mt-3">
														Password
													</p>
													<input
														type={values.showPassword ? "text" : "password"}
														placeholder="Enter Your Password"
														id="password"
														className="form-control p-3 f-13 font-mon-medium rounded-0 signup-fld"
														value={password}
														onChange={(e) => setPassword(e.target.value)}
													/>
													<div className="icon-password">
														<i
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															class="fa fa-eye"
															aria-hidden="true"
														></i>
													</div>
												</div>
											</form>

											<div class="d-flex">
												<div class="border-red mt-4">
													<button
														className="view_more btn font-17 font-roboto-medium"
														type="submit"
														onClick={(e) => SignInHandler(e)}
														disabled={loading}
													>
														{loading ? "Loading..." : "Sign in"}
													</button>
												</div>
											</div>
											<div className="mt-3">
												<Link
													to="/forgot"
													className="font-roboto-light f-15 forg-color mt-5"
												>
													Forgotten your password?
												</Link>
											</div>
										</div>
										<div className="col-lg-5 med-ps-5 text-start">
											<Link
												to="/signup"
												className="font-roboto-bold f-22 mt-5 dont-text"
											>
												Don't Have an Acount?
											</Link>
											<p className="f-16  font-roboto-light desc-right">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore.
											</p>
											<div class="d-flex">
												<div class="border-red mt-4">
													<button
														className="view_more btn font-17 font-roboto-medium"
														onClick={() => Navigate("/signup")}
													>
														Create an Account
													</button>
													{/*<button class="view_more btn font-17 mt-3 font-roboto-medium">*/}
													{/*  Create an Account*/}
													{/*</button>*/}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
