import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { avail } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ChangePasswordModal(props) {
  const { setIsOpenModalPass, 
    isOpenModalPass,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,

    changePassword,
    modalBtn,
    } = props;
  return (
    <>
      <Modal
        isOpen={isOpenModalPass}
        toggle={() => {
            setIsOpenModalPass(false);
        }}
        className="address-modal"
      >
        <ModalBody>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeIcon"
            onClick={() => setIsOpenModalPass(false)}
          />
          <div className="container">
          <div className="row user-fields"> 
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Current Password</p>
                      <input 
                        type="text"
                        placeholder="Contact" 
                        value={currentPassword}
                        onChange={(e)=>setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">New Password</p>
                      <input 
                        type="text" 
                        placeholder="Zip Code" 
                        value={newPassword}
                        onChange={(e)=>setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 mt-4">
                      <p className="input-label">Confirm Password</p>
                      <input 
                        type="text" 
                        placeholder="City" 
                        value={confirmPassword}
                        onChange={(e)=>setConfirmPassword(e.target.value)}
                      />
                    </div>
                   
                    <div className="col-md-12 col-sm-12 col-12 mt-4 " style={{textAlign: 'center'}}> 
                      <button 
                        type="button" 
                        className="btn saveBtn"
                        onClick={(e)=>changePassword(e)}
                        disabled={modalBtn}
                      >
                        {modalBtn ? "Loading..." : "Save"} 
                      </button>
                    </div>
                  </div>
          </div>
       
        </ModalBody>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
