import React from "react";

function TopSlider(props) {
  const { item, index } = props;
  return (
    <>
      <div>
        <div className="main-container" key={index}>
          <img src={item?.img} alt="" className="image-fluid image-width" />
          <div className="category-container">
            <p className="title">Women Wear</p>
            <p className="subTitle">
              Summer <span>Wear</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopSlider;
