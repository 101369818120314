import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../assets/css/Contact.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { PostContactUs } from "../../network/Network";

const Contact = () => {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [ firstName, setFirstName ] = useState("")
  const [ lastName, setLastName ] = useState("")
  const [name, setName] = useState("");
  const [ email, setEmail ] = useState("")
  const [ phone, setPhone ] = useState("")
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [ loading, setLoading ] = useState(false)
  const [checked, setChecked] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const ContactUsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!name || !email || !phone || !subject || !message) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      
      return;
    }
    if (name.length > 15) {
			toast.error("Enter name is more than 15 characters");
      setLoading(false);
			return;
		}
    if (phone.length > 11) {
			toast.error("Enter only 11 digits in phone");
      setLoading(false);
			return;
		}
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (message.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    let data = {
      name: name,
      email: email,
      mobile_number: phone,
      subject: subject,
      message: message,
    };
    console.log(data);
    PostContactUs(data, Token)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setName("")
        setEmail("");
        setPhone("");
        setSubject("")
        setMessage("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"Contact Us"} />
      <div className="pos-rel Contact">
        <div className="dark_black_div">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3 className="mt-4rem mb-280 font-roboto-bold f-32">
                Let's Create Progress Together
              </h3>
            </div>
          </div>
        </div>

        <div className="red_div p-5 p-res-contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="form_box">
                  <div className="row">
                  <div className="col-lg-12">
                  <input
                        placeholder="Name"
                        className="form-control f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                      ></input>
                  </div>
                  {/* <div className="col-lg-6">
                  <input
                        placeholder="Last Name"
                        className="form-control f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={lastName}
                        onChange={(e)=>setLastName(e.target.value)}
                      ></input>
                  </div> */}
                  <div className="col-lg-6">
                  <input
                        placeholder="Email"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      ></input>
                  </div>
                  <div className="col-lg-6">
                  <input
                  type="number"
                        placeholder="Phone"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
                      ></input>
                  </div>
                  <div className="col-lg-12">
                  <input
                        placeholder="Subject"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                      ></input>
                  </div>
                    {/* <div className="col-lg-6">
                      <input
                        placeholder="First Name"
                        className="form-control f-14 p-3 rounded-0 font-roboto-reg res-pad"
                      ></input>
                      <input
                        placeholder="Last Name"
                        className="form-control f-14 mt-4 p-3 rounded-0 font-roboto-reg res-pad"
                      ></input>
                    </div>

                    <div className="col-lg-6">
                      <input
                        placeholder="Email"
                        className="form-control media-mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad"
                      ></input>
                        
                      <select className="form-select mt-4 f-14 p-3 rounded-0 font-roboto-reg res-pad">
                        <option disabled>Select a subject</option>
                        <option>Select a subject</option>
                        <option>Select a subject</option>
                        <option>Select a subject</option>
                        <option>Select a subject</option>
                      </select>
                    </div> */}
                    
                    <div className="col-lg-12">
                      <textarea
                        className="form-control mt-4 txt-area p-3 rounded-0 f-14 font-roboto-reg res-pad"
                        rows={8}
                        placeholder="Drop us a message here…"
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-lg-12 text-right">
                      <div className="  mt-4">
                        <button  
                          type="button" 
                          className="btn  btn-primary view_more font-15"
                          onClick={(e)=>ContactUsHandler(e)}
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Send"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Contact;
