import React, { useState } from "react";
import { Update_logo, add1, logo } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { PostNewsLetter } from "../../network/Network";

function NewsLetter() {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const NewsLetterHandler = (e) => {
		e.preventDefault();

		if (!email) {
			toast.error("Please Enter Email");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		let data = {
			email: email,
		};
		console.log(data);
		PostNewsLetter(data)
			.then((res) => {
				setLoading(false);
				console.log(res);
				toast.success("Join Newsletter Successfully!!");
				setEmail("");
			})
			.catch((err) => {
				setLoading(false);
				console.log(err?.response?.data);
				if (err?.response?.data?.errors[0]) {
					toast.error(err?.response?.data?.errors[0]?.message);
					return;
				}

				// toast.error("Already Subscribe");
				console.log(err);
			});
	};

	console.log(PostNewsLetter, "donedeal");

	return (
		<>
			<div className="NewsLetter-container">
				<div className="container">
					<div>
						<div className="logo">
							<img src={Update_logo} className="image-fluid" alt="" />
						</div>
						<p className="mt-2">
							Subscribe to Our Newsletter to know Latest News & Updates
						</p>
						<div className="input-container mt-4">
							<input
								type="text"
								placeholder="Enter Your Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								// onKeyPress={(e)=>NewsLetterHandler(e)}
							/>
							<FontAwesomeIcon
								className="footer-icon arrow-icon"
								icon={faArrowRight}
								onClick={(e) => NewsLetterHandler(e)}
							/>
							{/* <button onClick={NewsLetterHandler}></button> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default NewsLetter;
