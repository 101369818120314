import React from "react";
import "../../assets/css/UrlHeader.css";
import queryString from "query-string";

const UrlHeader = (props) => {
  const {page} = props;
  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);
  return (
    <div className="page_detaiils_breadcrum text-center pt-3 pb-3">
      {/* <p className="m-0 font-contact">{currentUrl?.[3]}</p> */}
      <p className="m-0 font-contact">{page}</p>
     
      {/* <small className="mt-1">Home / Contact us</small> */}
    </div>
  );
};

export default UrlHeader;
