import React, { useState } from "react";
import ProductCard from "../../components/Card/ProductCard";
import MuiCategories from "../../components/Categories/MuiCategories";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import { arrival1, arrival2, arrival3 } from "../../constant";
import UrlHeader from "../../components/Header/UrlHeader";
import PercentOffAdds from "../../components/Adds/PercentOffAdds";
import PriceFilter from "../../components/Price Range/PriceFilter";

function NewArrivals() {
  const newArrivalData = [
    {
      id: 1,
      img: arrival1,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 2,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 3,
      img: arrival3,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 4,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 5,
      img: arrival1,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      id: 6,
      img: arrival2,
      title: "Your Product Name",
      price: "$130.00 ",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
  ];
  const categoriesData = [
    {
      id: 1,
      name: "Girls Wear (17)",
    },
    {
      id: 2,
      name: "Boys Wear (14)",
    },
    {
      id: 3,
      name: "Seasonal (12)",
    },
    {
      id: 4,
      name: "Accessories (15)",
    },
    {
      id: 5,
      name: "Boys Wear (14)",
    },
    {
      id: 6,
      name: "Seasonal (12)",
    },
    {
      id: 7,
      name: "Accessories (15)",
    },
  ];

  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [checked, setChecked] = useState("");

  const handleSelectCategory = (id) => {
    setChecked(id);
  };


  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"New Arrivals"}/>
      <div className="container">
        <div className="row category-margin">
          <div className="col-md-3">
            <div className="categories-container">
              <div className="header">
                <p className="title">Categories</p>
              </div>
              {categoriesData?.map((item, index) => {
                return <MuiCategories item={item} index={index} />;
              })}
            </div>
            <PriceFilter
              startPrice={startPrice}
              setStartPrice={setStartPrice}
              endPrice={endPrice}
              setEndPrice={setEndPrice}
            />
          </div>
          <div className="col-md-9">
            <div>
              <div className="header-result">
                <div>
                  <p className="result-text">Showing 1–16 of 17 results</p>
                </div>
                <div>
                  <select name="filter" id="filter" className="sizeSelect">
                    <option value="S"> Default Sorting</option>
                    <option value="M">Action</option>
                    <option value="L">Action</option>
                  </select>
                </div>
              </div>

              <div className="row">
                {newArrivalData?.map((item, index) => {
                  return (
                    <>
                      <div className="col-md-4 product-card">
                        <ProductCard item={item} index={index} />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PercentOffAdds />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default NewArrivals;
