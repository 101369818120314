import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Forgot.css";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import { forgotPasswordOtpVerify } from "../../redux/actions/AuthActions";

const ForgotPasswordOtp = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch()
  const [ otpCode, setOtpCode ] = useState("")
  const [checked, setChecked] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  console.log(Location);

  const OtpVerificationHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!otpCode) {
      toast.error("please enter OTP Code");
      setLoading(false);
      return;
    }
    let data = {
      code: otpCode,
    };
    let param = {
      email: Location?.state,
      reset_token: otpCode,
    };
    console.log(data);
    let send = await dispatch(forgotPasswordOtpVerify(data, param, Navigate));
    setTimeout(() => {
      setLoading(false);
    }, 600);
  };

  // const SubmitHandler = (e) => {
  //   e.preventDefault();
  //   Navigate("/new-password");
  // };
  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader page={"OTP Code"}/>
      <div className="forgot_passwordOTP">
        <div>
          <div>
            <div className="dark_black_div">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h3 className="mt-4rem font-roboto-bold f-31">
                    Create New Password
                  </h3>
                  <p className="f-16 font-roboto-regular mt-3">
                    Enter OTP Code
                  </p>
                  <div className="form-signup px-3">
                    <input
                      type="text"
                      placeholder="Enter OTP Here"
                      className="form-control signup-fld p-3 f-18 font-roboto-medium"
                      value={otpCode}
                      onChange={(e)=>setOtpCode(e.target.value)}
                    />
                    <div className="d-flex">
                      <button
                        className="btn-danger brbd-no-rad bg-red btn f-18 form-control p-3 font-roboto-medium mt-4"
                        onClick={(e) => OtpVerificationHandler(e)}
                        disabled={loading}
                      >
                        {loading ? "Loading.. " : "Submit" }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordOtp;
