import React, { useEffect } from "react";
// import { avail } from "../../constant";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

function Avail(props) {
  const { page, AvailData } = props;

  useEffect(() => {
    Aos.init();
  },[])
  const navigate = useNavigate();

  const navigateToProducts = () => {
    // 👇️ navigate to /contacts
    navigate('/all-product-page');
  };

  return (
    <>
      <div className="Avail">
        <div className="container">
          <div className="avail-inner-container">
            <div className="row">
              <div className="col-md-5">
                <div className="img-container" 
                  // data-aos="fade-right"
                  // data-aos-duration="3000"
                  // data-aos-easing="ease-in-sine"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="3000"
                  >
                  <img
                    src={AvailData?.[0]?.img}
                    className="image-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="detail-card"
                  //  data-aos="fade-left"
                  //  data-aos-duration="3000"
                  //  data-aos-easing="ease-in-sine"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="3000"
                >
                  <h2>{AvailData?.[0]?.title}</h2>
                  <p>{AvailData?.[0]?.desc}</p>
                  <div className="button-container">
                    {page == "about" ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary learnMore-btn"
                        >
                          Learn More
                        </button>
                        <button type="button" className="btn btn-primary">
                          Pre-Order
                        </button>
                      </>
                    ) : (
                      <button onClick={navigateToProducts} type="button" className="btn btn-primary">
                        Shop Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Avail;
