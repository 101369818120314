import React from "react";
import { Rating } from "react-simple-star-rating";

function ReviewBox(props) {
  const {
    name,
    setName,
    email,
    setEmail,
    comment,
    setComment,
    SubmitRating,
    handleRating,
    rating,
    loading,
  } = props;
  return (
    <>
      <section className="leave_comment">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="head">
                <h3>Post Your Reviews</h3>
              </div>
              <form action="">
                <div className="row">
                <div className="col-md-12">
                    <div className="rating-inner-cont mb-4">
                      <Rating 
                        onClick={handleRating} 
                        ratingValue={rating} 
                      
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Comment"
                        value={comment}
                        onChange={(e)=>setComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button type="submit" className="btn"
                      onClick={(e)=>SubmitRating(e)}
                      disabled={loading}
                    >
                      {loading? "Loading..." : "Send" }
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewBox;
