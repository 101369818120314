import Aos from "aos";
import React, { useEffect, useState } from "react";
import Avail from "../../components/Avail/Avail";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import OurTeamCard from "../../components/OurTeam/OurTeamCard";
import {
	about2,
	add1,
	avail,
	new1,
	new2,
	team1,
	team2,
	team3,
	team4,
} from "../../constant";
import { OurTeam, PageData } from "../../network/Network";
import { Link } from "react-router-dom";

function About(props) {
	// const [ teamData, setTeamData] = useState([])
	const [aboutData, setAboutData] = useState();

	const teamData = [
		{
			id: 1,
			img: team1,
			name: "Grace Hall",
			desc: "Lorem Ipsum",
		},
		{
			id: 2,
			img: team2,
			name: "James Rodriguez",
			desc: "Lorem Ipsum",
		},
		{
			id: 3,
			img: team3,
			name: "Grace Hall",
			desc: "Lorem Ipsum",
		},
		{
			id: 4,
			img: team4,
			name: "James Rodriguez",
			desc: "Lorem Ipsum",
		},
	];

	const AvailData = [
		{
			id: 1,
			img: avail,
			title: "Pre-Order & Avail 50% Off",
			desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.",
		},
	];
	useEffect(() => {
		Aos.init();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		OurTeam()
			.then((res) => {
				console.log(res);
				// setTeamData(res?.data?.data?.products?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		let type = "About Us";
		PageData(type)
			.then((res) => {
				console.log(res);
				setAboutData(res?.data?.data?.pages);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<Header />
			<UrlHeader page={"About us"} />
			<div className="about">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div
								className="inner-container"
								data-aos="fade-right"
								data-aos-offset="300"
								data-aos-easing="ease-in-sine"
							>
								<h2 className="about-title">
									<span>What</span> we do
								</h2>
								<p
									className="para mt-4"
									dangerouslySetInnerHTML={{ __html: aboutData?.value }}
								/>
								{/* <p className="para mt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </p>
                <p className="para">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p> */}
								<div className="mt-3">
									<Link to="/all-product-page">
										<button type="button" className="btn btn-primary">
											Shop Now
										</button>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div
								className="right-imgbox"
								data-aos="fade-left"
								data-aos-offset="300"
								data-aos-easing="ease-in-sine"
							>
								<img src={about2} alt="" className="image-fluid image-width" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="AddsBanner-container"
				data-aos="flip-up"
				data-aos-duration="3000"
			>
				<div className="inner-container">
					<h4>Shop with us 50% OFF or spend 50 GBP and get free shipping</h4>
					<div className="button-container">
						<div>
							<Link to="/all-product-page">
								<button type="button" className="btn btn-primary">
									Shop Now
								</button>
							</Link>
						</div>
						<div>
							<Link to="/contact">
								<button type="button" className="btn btn-primary">
									Contact Us
								</button>
							</Link>
						</div>
					</div>
				</div>
				<img src={add1} className="image-fluid image-width" alt="" />
			</div>
			{/* New Arrivals  */}
			<div className="about-newarrival">
				<div className="container">
					<div className="row">
						<div
							className="col-md-3"
							data-aos="fade-up"
							data-aos-duration="3000"
						>
							<div className="right-imgbox1">
								<img src={new1} alt="" className="image-fluid image-width" />
							</div>
						</div>
						<div
							className="col-md-3"
							data-aos="fade-up"
							data-aos-duration="3000"
						>
							<div className="right-imgbox2">
								<img src={new2} alt="" className="image-fluid image-width" />
							</div>
						</div>
						<div className="col-md-6">
							<div
								className="inner-container"
								data-aos="fade-left"
								data-aos-offset="300"
								data-aos-easing="ease-in-sine"
							>
								<h2 className="about-title">
									<span>New </span> Arrivals
								</h2>
								<p className="para mt-4">
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry. Lorem Ipsum has been the industry's
									standard dummy text ever since the 1500s, when an unknown
									printer took a galley of type and scrambled it to make a type
									specimen book. It has survived not only five centuries, but
									also the leap into electronic typesetting, remaining
									essentially unchanged.
								</p>
								<p className="para">
									It is a long established fact that a reader will be distracted
									by the readable content of a page when looking at its layout.
									The point of using Lorem Ipsum is that it has a more-or-less
									normal distribution of letters, as opposed to using 'Content
									here, content here', making it look like readable English.
								</p>
								<div>
									<button type="button" className="btn btn-primary">
										View Categories
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* New arrival end  */}
			<Avail page={"about"} AvailData={AvailData} />
			{/* Team  */}
			<div className="ourTeam">
				<div className="container">
					<h2 className="title">
						<span>Meet</span> our Team
					</h2>
					<div className="row">
						{teamData?.map((item, index) => {
							return <OurTeamCard item={item} index={index} />;
						})}
					</div>
				</div>
			</div>
			{/* Team end  */}
			<NewsLetter />
			<Footer />
		</>
	);
}

export default About;
