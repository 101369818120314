import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import "../../assets/css/Checkout.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Footer from "../../components/Footer/Footer";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import Backdrop from "@mui/material/Backdrop";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StripeForm from "../../components/Stripe/StripeForm";
import AddressModal from "../../components/Modal/AddressModal";
import AddressCard from "../../components/Card/AddressCard";
import OrderSummary from "../../components/Cart/OrderSummary";
import { PlaceOrder } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { ClearCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";

const Checkout = () => {
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const Token = useSelector((state) => state.AuthReducer.token);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [open, setOpen] = React.useState(false);
  const [isOpenModal, setIsOpenModal ] = useState(false)
  const [promoCode, setPromoCode ] = useState("")
  const [ firstName, setFirstName ] = useState("")
  const [ lastName, setLastName ] = useState("")
  const [ phone, setPhone ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ address, setAddress ] = useState("")
  const [ city, setCity ] = useState("")
  const [ country, setCountry ] = useState("")
  const [ userState, setUserState ] = useState("")
  const [ zipCode, setZipCode ] = useState("")
  const [ loading,setLoading ] = useState(false)
  const [cardToken, setCardToken] = useState("");
  const [checked, setChecked] = useState("");

  const Location = useLocation();
  console.log(Location);
  const CheckOutData = Location?.state?.data;
  console.log(CheckOutData?.CartData)

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    console.log(selectedValue)
  }, [selectedValue])

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const AddressData = [
    {
      id:1,
      country: 'United States',
      state : 'state',
      address: '10515 Fox Ave Fairdale, Kentucky(KY), 40118',
      phone: '+123456789',
    },
    // {
    //   id:2,
    //   country: 'United States',
    //   state : 'state',
    //   address: '10515 Fox Ave Fairdale, Kentucky(KY), 40118',
    //   phone: '+123456789',
    // },

  ]

  const PaymentHandler = (e) => {
    e.preventDefault();
    if (!cardToken) {
      // toast.error("Invalid Card Details");
      return;
    }
  };

  const PlaceOrderHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    let userData = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      region: country,
      postalCode: zipCode,
      townCity: city,
      address: address,
    };
    let data = {
      cart: CheckOutData?.CartData,
      // discount: 0,
      customer_info: userData,
      stripe_token: cardToken,
      role: "customer",
    };
    console.log(data);
    PlaceOrder(data, Token)
      .then((res) => {
        setLoading(false);
        console.log(res);
        dispatch(ClearCart());
        toast.success("Order placed successfully!");
        handleToggle()
        setTimeout(() => {
          Navigate(`/`, { state: { data: res?.data?.data?.order } });
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Header 
        setChecked={setChecked}
      />
      <UrlHeader  page={"Checkout"}/>
      <div className="checkout_page">
        <div>
          <div className="dark_black_div pb-5">
            <div className="container">
              <div className="row px-3">
                <div className="offset-lg-1 col-lg-6 pe-5 text-start pe-res-3">
                  <div className="d-price mt-4rem d-med-login">
                    <p className="font-roboto-bold f-20">Shipment Details</p>
                    {/* <Link
                      to="/signin"
                      className="font-mon-medium text-white f-15"
                    >
                      Already have an account? Log in
                    </Link> */}
                  </div>
                  <p className="f-15 font-roboto-bold">Use Saved Addresses</p>
                  <div className="form-signup">
                    {AddressData?.map((item,index)=>{
                      return(
                        <AddressCard 
                          item={item}
                          index={index}
                          handleChange={handleChange}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                      />
                      )
                    })}
                    
                    <button 
                      className="view_more btn bt-hover-color font-roboto-bold f-15"
                      onClick={()=>setIsOpenModal(true)}
                    >
                      Add New Address
                    </button>
                    <p className="font-roboto-medium f-15 mt-5 mb-2">
                      Shipment Address
                    </p>
                    <select className="form-control font-roboto-regular signup-fld f-15 ">
                      <option className="font-roboto-medium f-15">
                        Select shipment method
                      </option>
                    </select>
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="font-roboto-medium f-15 mt-4 mb-2">
                          First Name
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your Last Name"
                          className="form-control f-15 font-roboto-regular signup-fld"
                          value={firstName}
                          onChange={(e)=>setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6">
                        <p className="font-roboto-medium f-15 mt-4 mb-2">
                          Last Name
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your Last Name"
                          className="form-control f-15 font-roboto-regular signup-fld"
                          value={lastName}
                          onChange={(e)=>setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="f-15 font-roboto-medium mt-4 mb-2">
                          Phone{" "}
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your Phone Number"
                          className="form-control f-15 font-roboto-regular signup-fld"
                          value={phone}
                          onChange={(e)=>setPhone(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6">
                        <p className="font-roboto-medium f-15 mt-4 mb-2">
                          Email{" "}
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your Email Address"
                          className="form-control f-15 font-roboto-medium signup-fld"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <p className="font-roboto-regular f-15 mt-4 mb-2">
                      Suite, Apartment, etc{" "}
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Your Address"
                      className="form-control f-15 font-roboto-medium signup-fld"
                      value={address}
                      onChange={(e)=>setAddress(e.target.value)}
                    />

                    <p className="font-roboto-regular f-15 mt-4 mb-2">City </p>
                    <input
                      type="text"
                      placeholder="Enter Your City"
                      className="form-control f-15 font-roboto-medium signup-fld"
                      value={city}
                      onChange={(e)=>setCity(e.target.value)}
                    />
                    <div className="row">
                      <div className="col-lg-4">
                        <p className="font-roboto-regular f-15 mt-4 mb-2">
                          Country/Region{" "}
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your Country"
                          className="form-control f-15 font-roboto-medium signup-fld"
                          value={country}
                          onChange={(e)=>setCountry(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-4">
                        <p className="font-roboto-regular f-15 mt-4 mb-2">
                          State{" "}
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your State"
                          className="form-control font-roboto-medium f-15 signup-fld"
                          value={userState}
                          onChange={(e)=>setUserState(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-4">
                        <p className="font-roboto-regular f-15 mt-4 mb-2">
                          Zip Code{" "}
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Your Zip"
                          className="form-control font-roboto-medium f-15 signup-fld"
                          value={zipCode}
                          onChange={(e)=>setZipCode(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="stripe-container mt-5">
                          <StripeForm   
                            PaymentHandler={PaymentHandler}
                            setCardToken={setCardToken}
                            cardToken={cardToken}
                          />
                        </div>
                        </div>
                    </div>
                    {/* <div className='saved_payment_box mt-4 mb-4'>
                                        <div className='saved_payment_header'>
                                            <p className='f-18 fw-bold font-roboto mb-0'>Saved Payment Method</p>
                                        </div>
                                        <div className='saved_payment_body'>
                                            <div className='d-price'>
                                                <div className='d-flex'>
                                                    <CheckCircleIcon className='icon_tick me-4' />
                                                    <div className='me-3'>
                                                        <img className='' height={30} width={90} src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt='visa_icon' />
                                                    </div>
                                                    <div className='ms-3'>
                                                        <small>My Persoanl Card</small>
                                                        <p className='my-0 f-15 font-roboto'>***********4242</p>
                                                    </div>

                                                </div>
                                                <div>
                                                    <DeleteIcon className='icon_tick' />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  </div>
                </div>

                <div className="col-lg-4">
                  <OrderSummary 
                    promoCode={promoCode}
                    setPromoCode={setPromoCode}
                    CheckOutData={CheckOutData}
                  />
                </div>

                <div className="offset-lg-1 col-lg-10">
                  <div className="brdr-top-light pt-3 mt-4 mb-5">
                    <div className="row">
                      {/* <div className='col-lg-8 mt-4'>
                          <Checkbox
                              // {...label}
                              defaultChecked
                              sx={{
                                  color: pink[800],
                                  '&.Mui-checked': {
                                      color: pink[600],
                                  },
                              }}
                          />
                          <span className='font-roboto f-17 ms-4'>Proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
                      </div> */}
                      <div className="col-lg-12 mt-4 text-right">
                        <button
                        onClick={(e)=>PlaceOrderHandler(e)}
                          className="view_more btn f-21 font-roboto-medium px-5 bt-hover-color mt-2"
                          disabled={loading}
                        >
                         {loading ? "Loading.." : "Confirm Order"} 
                        </button>
                      </div>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={open}
                        onClick={handleClose}
                      >
                        <div className="thankYou_div p-5">
                          <p className="f-24 font-pop-med mx-4 mb-2">
                            Thank You
                          </p>
                          <p className="f-24 font-pop-med mx-4">
                            Your Order has been Placed
                          </p>
                        </div>
                      </Backdrop>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
      <AddressModal 
        setIsOpenModal={setIsOpenModal} 
        isOpenModal={isOpenModal} 
      />
    </>
  );
};

export default Checkout;
