import React, { useState } from "react";
import Header from "../../components/Header/Header";
import UrlHeader from "../../components/Header/UrlHeader";
import "../../assets/css/Forgot.css";
// import "../Pages/ForgotPasswordConfirm.css";

const NewPassword = () => {
  const [ password, setPassword ] = useState("")
  const [ confirmPassword, setConfirmPassword ] = useState("")
  const [checked, setChecked] = useState("");
  
  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  return (
    <>
      <Header 
         setChecked={setChecked} 
      />
      <UrlHeader page={"New Password"}/>
      <div className="ForgotPasswordConfirm">
        <div>
          <div className="dark_black_div">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h3 className="mt-4rem font-roboto-bold f-31">
                    Create New Password
                  </h3>
                  <p className="f-16 font-roboto-bold mt-3">
                    Create Your New Password
                  </p>
                  <div className="form-signup px-3">
                    <input
                      type="password"
                      placeholder="New Password"
                      className="form-control p-3 signup-fld f-15 font-roboto"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                    />
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className="form-control mt-3 p-3 signup-fld f-15 font-roboto"
                      value={confirmPassword}
                      onChange={(e)=>setConfirmPassword(e.target.value)}
                    />
                    <div className="d-flex">
                      <button 
                        className="btn-danger brbd-no-rad bg-red btn font-18 form-control font-roboto mt-4"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
